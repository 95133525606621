import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {Store} from "@ngrx/store";
import {Router} from "@angular/router";
import {GTrackingService} from "../GoogleTrackig/g-tracking.service";
@Injectable()
export class Api {
  constructor(public http: HttpClient,
              public store: Store,
              public dataLayerService: GTrackingService,
              public router: Router) {
  }

  protected getBaseUrl(): string {
    return environment.apiUrl;
  }

}
