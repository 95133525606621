import { Injectable } from '@angular/core';
import { Api } from "../provider/api.service";
import { BehaviorSubject, Observable } from "rxjs";



@Injectable({
  providedIn: 'root'
})
export class DrivingExperienceService extends Api {

  private configurationData: any = {
    prezzoTotale: 0,
    servizi: Array<any>(),
  } as any;
  configurationData$ = new BehaviorSubject<any>(this.configurationData);

  updateConfigurationData(data: any) {
    Object.keys(data).forEach(key => {
      this.configurationData[key] = data[key];
    });

    // Calcola il prezzo totale
    this.calcolaPrezzoTotale();


    this.configurationData$.next(this.configurationData);
  }

  calcolaPrezzoTotale() {
    let prezzoTotale = parseFloat(this.configurationData.prezzo) || 0;

    if (this.configurationData.servizi) {
      for (const servizio of this.configurationData.servizi) {
        prezzoTotale += parseFloat(servizio.prezzo);
      }
    }

    this.configurationData.prezzoTotale = prezzoTotale;
  }

  svuotaEspeienzaGuida() {
    this.configurationData = {
      prezzoTotale: 0,
      servizi: Array<any>(),
    } as any;
    this.configurationData$.next(this.configurationData);
  }



  /*Richieste http*/

  prezziCircuiti(params: any): any {
    return this.http.get(this.getBaseUrl() + 'store/prezzi-circuiti', { params });
  }

  getVeicolis(params?: any): Observable<any> {
    return this.http.get(this.getBaseUrl() + 'store/list-car', { params });
  }

  getCircuiti(params?: any): Observable<any> {
    return this.http.get(this.getBaseUrl() + 'store/list-regioni', { params });
  }

  getCalendario(): Observable<any> {
    return this.http.get(this.getBaseUrl() + 'store/calendario-eventi');
  }

  getTipoEsperienze(params: any) {
    return this.http.get(this.getBaseUrl() + 'store/tipo-esperienze', { params });
  }

  updateEsperienza(params: any) {
    return this.http.post(this.getBaseUrl() + 'cart/update-esperienza', params);
  }

  cessioneEsperienza(params: any) {
    return this.http.post(this.getBaseUrl() + 'store/invito-cessione-esperienza', params);
  }

  getPrezziCircuito(params: any): Observable<any> {
    return this.http.get(this.getBaseUrl() + 'store/prezzi-veicolo-attivazione', { params });
  }

  getCircuitiPrezzi(): Observable<any> {
    return this.http.get(this.getBaseUrl() + 'store/circuito-da-prezzi');
  }

  getCircuito(): Observable<any> {
    return this.http.get(this.getBaseUrl() + 'store/circuito-da-prezzi');
  }

  getVeicoliCircuito(params: any): Observable<any> {
    return this.http.get(this.getBaseUrl() + 'store/veicolo-da-prezzi', { params });
  }

  getInfoEsperienza(circutio: string, veicolo: string): Observable<any> {
    return this.http.get(this.getBaseUrl() + `store/info-esperineza/${circutio}/${veicolo}`);
  }




  cir: any
  vei: any


}


