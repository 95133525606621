import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppComponent} from './app.component';
import {LayoutComponent} from "./layout/layout.component";
import {NavbarComponent} from "./shared/components/navbar/navbar.component";
import {FooterComponent} from "./shared/components/footer/footer.component";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {AppRoutingModule} from "./app-routing.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {RequestInterceptor} from "../core/provider/request.interceptor";
import {AuthService} from "../core/auth/auth.service";
import {AuthGuard} from "../core/auth/auth.guard";
import {LocationStrategy, NgOptimizedImage, PathLocationStrategy, registerLocaleData} from "@angular/common";

// import function to register Swiper custom elements
import {JwtModule} from "@auth0/angular-jwt";
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from "@ngrx/store-devtools";
import {EffectsModule} from '@ngrx/effects';
import {cartReducer} from "../core/carrello/reducer/cart.reducer";
import {CartEffect} from "../core/carrello/effects/cart.effect";
import {RadioButtonModule} from "primeng/radiobutton";
import {SpinnerComponent} from "./shared/components/spinner/spinner.component";
import {AuthComponent} from "./shared/components/auth/auth.component";
import { CircuitiComponent } from './circuiti/circuiti.component';
import {MapItalyComponent} from "./shared/components/map-italy/map-italy.component";
import localeIt from '@angular/common/locales/it';

import { ManutenzioneComponent } from './manutenzione/manutenzione.component';
import { TipologiaGuidaComponent } from './shared/components/tipologia-guida/tipologia-guida.component';

import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { BlankPageComponent } from './blank-page/blank-page.component';
import { ProMoresComponent } from './pro-mores/pro-mores.component';
import { HeroBannerSEC1Component } from './pro-mores/hero-banner-sec1/hero-banner-sec1.component';
import { InfoPreviewSEC2Component } from './pro-mores/info-preview-sec2/info-preview-sec2.component';
import { ImgCarSEC3Component } from './pro-mores/img-car-sec3/img-car-sec3.component';
import { InfoEventoComponent } from './pro-mores/info-evento/info-evento.component';
import { VideoSEC5Component } from './pro-mores/video-sec5/video-sec5.component';
import { AutoDromoSEC6Component } from './pro-mores/auto-dromo-sec6/auto-dromo-sec6.component';
import { StepSEC7Component } from './pro-mores/step-sec7/step-sec7.component';
import { MosaicoSEC8Component } from './pro-mores/mosaico-sec8/mosaico-sec8.component';
import { CalendarioSEC9Component } from './pro-mores/calendario-sec9/calendario-sec9.component';
import { CascoSEC10Component } from './pro-mores/casco-sec10/casco-sec10.component';
import { BuilderSEC11Component } from './pro-mores/builder-sec11/builder-sec11.component';
import { ContattaciSEC12Component } from './pro-mores/contattaci-sec12/contattaci-sec12.component';

registerLocaleData(localeIt, 'it')

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    AppComponent,





  ],
  imports: [
    LottieModule.forRoot({player: playerFactory}),
    StoreModule.forRoot({cart: cartReducer}),
    EffectsModule.forFeature([CartEffect]),
    StoreDevtoolsModule.instrument({maxAge: 25}),
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'it',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgOptimizedImage,
    JwtModule.forRoot({
      config: {
        tokenGetter: () => localStorage.getItem('auth_app_token')
      }
    }),
    EffectsModule.forRoot([]),
    RadioButtonModule,
    SpinnerComponent,
    FooterComponent,
    NavbarComponent,
    AuthComponent,
    MapItalyComponent,
    ManutenzioneComponent,
    CircuitiComponent,
    LayoutComponent,
  ],
  providers: [
    AuthGuard,
    AuthService,
    {provide: LocationStrategy, useClass: PathLocationStrategy},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: "it-IT"
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
