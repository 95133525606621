<div class="min-h-screen" (click)="goTo('circuito')">
  <img class='bg-cover object-cover	w-full h-screen' src="/assets/bg/cover-circuiti.webp" alt="Cofanetti Cover"
    width="1728" height="1117" />
  <div class="absolute left-0 right-0 bottom-[0rem] flex justify-center items-center">
    <div class="hero-text text-white text-center ">
      <h1 class="aviano-bold text-4xl max-sm:text-lg	sm:mb-2"> siamo in tutta italia, <br> scegli solo dove e quando
      </h1>
      <div class="flex flex-col items-center mt-[2rem] mb-[2rem]">
        <p class="text-2xl max-sm:text-sm px-4 aviano-bold spacing tracking-[10px]	">
          <br>
          <!--  <span class="text-xl max-sm:text-sm px-4 rajdhani-medium spacing tracking-[2px]	">Sarà indimenticabile! Vuoi idee per incentivare i tuoi clienti TOP, colleghi, dirigenti Rappresentanti o
              <br> Agenti? Qualsiasi sia la tua idea di Incentive, regalerai emozioni uniche e legami duraturi con i tuoi
              <br> clienti importanti e colleghi.
              </span>-->

        </p>

      </div>
      <div class="flex justify-center items-center">
        <svg width="64" height="44" viewBox="0 0 64 44" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_1_426)">
            <path id="tri1"
              d="M32.0104 36.9797C21.3471 31.7756 10.6839 26.5715 0 21.3674L32.0104 44.0205L64.0207 21.3674C53.3368 26.5511 42.6736 31.7552 32.0104 36.9797Z"
              fill="#3a3736" />
            <path id="tri2"
              d="M32.0104 15.6122C21.3264 10.4082 10.6632 5.20408 0 0L32.0104 22.6531L64 0C53.3368 5.20408 42.6736 10.4082 32.0104 15.6122Z"
              fill="#3a3736" />
          </g>
          <defs>
            <clipPath id="clip0_1_426">
              <rect width="64" height="44" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  </div>
</div>


<section class="" id="circuito">
  <div class=" pt-8 max-xl:hidden">


    <h3 class="my-3 aviano-bold text-3xl text-center"> scegli UN CIRCUITO </h3>

    <h5 class="my-3 aviano-regular text-xl text-center">Clicca sulla tua regione</h5>

    <div class=""
      style="background-image: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)), url('/assets/bg/1.png'); background-position: center; background-repeat: no-repeat; background-size: cover">

      <div
        class="grid gap-x-3 max-w-max h-[85vh] mx-auto justify-center justify-items-center content-center items-center"
        [ngClass]="{'grid-cols-1': !selectedRegion, 'grid-cols-2': selectedRegion}">

        <div [ngClass]="{'w-full': !selectedRegion, 'col-span-1': selectedRegion}">
          <app-map-italy (onRegionSelect)="handleRegionSelect($event)"></app-map-italy>
        </div>

        <div *ngIf="selectedRegion" class="col-span-1 w-[90%]">
          <div class="grid grid-cols-2 gap-4 gap-y-8 justify-center">
            <div class="flex" *ngFor="let circuit of selectedRegion?.circuits; let index = index">
              <div (click)="selectCircuito(circuit)" class="bg-[#ffffff87] border-2 cursor-pointer p-4 relative"
                [ngClass]="{'border-[#CC0000]': circuitSelected == circuit.idcircuito, 'border-black': circuitSelected != circuit.idcircuito}">
                <img class="text-center h-[60px] mx-auto" [src]=" image + 'Circuiti/' + circuit.filename" width="100"
                  height="100" alt="" />
                <h5 class="truncate-cap text-center aviano-bold py-2 text-l"
                  [ngClass]="{'text-[#CC0000]': circuitSelected == circuit.idcircuito, 'text-black': circuitSelected != circuit.idcircuito}">
                  {{ circuit.nomignolo }}, <strong>({{ circuit.provicia }})</strong></h5>
                <div class="flex justify-center">
                  <p class="hs-medium-italic">A partire da {{ circuit.prezzo }} €</p>
                  <div
                    class="absolute text-white bottom-[-12px] inline-flex items-center transform -skew-x-30 font-bold uppercase tracking-wider px-2"
                    [ngClass]="{'bg-[#CC0000]': circuitSelected == circuit.idcircuito, 'bg-black': circuitSelected != circuit.idcircuito}">
                    {{ circuit.lunghezza }} km
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ng-container *ngIf="selectedRegion?.circuits.length == 0 && selectedRegion != undefined">
            <h3 class="aviano-bold text-center text-xl px-5 py-5">
              {{ 'CALEDNARIO.noCircuiti' | translate }}
            </h3>
          </ng-container>
        </div>

        <div class="col-span-2">
          <div class="flex justify-center items-center" *ngIf="circuitSelected">
            <button routerLink="{{ selectedCircuit.slug }}"
              class="aviano-bold min-w-4xl mx-auto bg-[#CC0000] text-white text-center p-3">
              Vai al circuito
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>

  <h3 class="pt-10 z-10 aviano-bold bg-[#CC0000] text-white text-3xl text-center max-xl:hidden"> oppure confronta le
    opzioni </h3>

  <div class="odd:bg-[#CC0000] py-12 shadow even:bg-[#C7C7C7]" *ngFor="let icon of listaCircuiti">

    <div class="flex py-5 flex-col items-center">
      <h3 class=" aviano-black text-3xl odd:text-white even:text-black mx-4">i nostri circuiti {{
        icon[0].posizione?.toLowerCase() != 'isole' ? 'al' : 'nelle' }} {{ icon[0].posizione }}</h3>
      <p class="text-white hs-italic">(scorri verso destra per vedere tutti i circuiti {{
        icon[0].posizione?.toLowerCase() != 'isole' ? 'del' : 'nelle' }} {{ icon[0].posizione }}) </p>
    </div>

    <div class=" flex gap-x-4 overflow-x-auto mx-4 ">


      <div class="flex" *ngFor="let item of icon">

        <div class="bg-white p-2 shadow min-w-[250px]">
          <div class="flex w-full justify-center text-lg rajdhani-medium text-center">{{ item.cognome }}
            <strong class="mx-2">({{ item.provincia }})</strong>
          </div>
          <img class="text-center h-[60px] mx-auto" [src]=" image +'Circuiti/' + item.fileName" width="100" height="100"
            alt="" />

          <div class="flex flex-col my-2">
            <div class="flex gap-x-3 justify-between bg-[#ECECEC] p-1">
              <p>Lunghezza</p>
              <p>{{ item.lunghezza * 1000 }}m</p>
            </div>
            <div class="flex gap-x-3 justify-between  p-1">
              <p>Rettilineo </p>
              <p>{{ item.lunghezzaRettilineo }}m</p>
            </div>
            <div class="flex gap-x-3 justify-between bg-[#ECECEC]  p-1">
              <p>Curve</p>
              <p>{{ item.nCurve }}</p>
            </div>
          </div>

          <div class="bg-[#FFD6D6] text-center hs-semibold-italic">
            da {{ item.prezzo | currency:'EUR':'symbol':'1.2-2':'it'}}
          </div>

          <div routerLink="{{ item.slug }}"
            class="bg-transparent uppercase cursor-pointer text-center border-2 border-[#CC0000] p-1 mt-2 hover:text-white hover:bg-[#CC0000] text-[#CC0000]">
            Scegli l'auto
          </div>

        </div>


      </div>

    </div>
  </div>

</section>


<section>

  <div class=""
    style="background-image: url('/assets/bg/2.png'); background-position: center; background-repeat: no-repeat; background-size: cover">

    <h3 class="pt-5 aviano-bold text-3xl text-center">L’esperienza dell’anno</h3>

    <p class="aviano-bold text-center text-xl">all inclusive</p>


    <div class="grid grid-cols-2 max-sm:grid-cols-1 max-w-[90%] mx-auto">

      <div class="flex items-center mt-6 mb-4 max-w-[50%] mx-auto">
        <a data-fancybox href="https://vimeo.com/870997829" class="">
          <img style="object-fit: cover" src="/assets/bg/come_funziona.webp" alt="Titolo del Video" class=" mx-auto">
        </a>
      </div>

      <div class="col-span-1 flex flex-col justify-start py-3 aviano-bold text-2xl">
        <div class="my-3 flex items-center" *ngFor="let item of servizi">

          <div class="w-[29px] h-[29px] mr-5 flex items-center justify-center">
            <img [src]="item.svg" class="w-full" alt="">
          </div>
          {{ item.titolo }}
        </div>

      </div>
    </div>

  </div>

</section>