import {AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'app-map-italy',
  standalone: true,
  templateUrl: './map-italy.component.html',
  styleUrls: ['./map-italy.component.scss']
})
export class MapItalyComponent implements OnInit, AfterViewInit {

  @ViewChild('pathRef') pathRef!: ElementRef<SVGPathElement>;
  selectedPath: string | null = null;
  @Output() onRegionSelect: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit(): void {
    const parmas = {
      target: {
        id: 4
      }
    }
    this.handlePathClick(parmas)
    document.getElementById("4");
  }

  ngAfterViewInit() {
    const parmas = {
      target: {
        id: 4
      }
    }
    this.handlePathClick(parmas)
    document.getElementById("4");
  }

  handlePathClick($event: any): void {
    const pathId = ($event.target as SVGPathElement).id;
    const path = $event.target

    const newSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    const newPath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    newPath.setAttribute('d', path.getAttribute('d'));
    newPath.setAttribute('fill', '#FFFFFF');
    newPath.setAttribute('fill-opacity', '1');
    newPath.setAttribute('stroke', '#CC0000');

    const bbox = path.getBBox();
    const x = bbox.x - 10;
    const y = bbox.y - 10;
    const width = bbox.width + 20;
    const height = bbox.height + 20;

    const viewBox = `${x} ${y} ${width} ${height}`;

    newSvg.setAttribute('width', '200');
    newSvg.setAttribute('height', '200');
    newSvg.setAttribute('viewBox', viewBox);
    newSvg.setAttribute('preserveAspectRatio', 'xMidYMid meet');

    newSvg.appendChild(newPath);

    const serializer = new XMLSerializer();
    const newSvgString = serializer.serializeToString(newSvg);

    // navigator.clipboard.writeText(newSvgString);

    this.selectedPath = pathId;

    const params = {
      idRegione: pathId,
      svg: newSvgString
    };

    this.onRegionSelect.emit(params);

    // Assuming you have an `onRegionSelect` method in your parent component
    // that handles the region selection
    // this.onRegionSelect(params);
  }
}
