<app-navbar *ngIf="!checkRoute()"></app-navbar>
<div *ngIf="checkRoute()" class="flex flex-row justify-center py-4">
    <a href="/">
        <img [src]="backgroundColor == 'white' ? '/assets/images/logo_nero.webp' : '/assets/images/logo_wecanrace.png'" alt="logo" width="100" height="100" />
    </a>
</div>

<router-outlet></router-outlet>
<app-footer></app-footer>

<app-auth *ngIf="openModal"></app-auth>