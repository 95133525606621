import { Component, OnInit } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import * as CartSelectors from '../../../../core/carrello/reducer/cart.selectors';
import { Observable } from 'rxjs';
import * as CartActions from '../../../../core/carrello/action/cart.action';
import { Router, RouterLink } from '@angular/router';
import { CommonModule, NgClass, NgIf } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { SidebarModule } from 'primeng/sidebar';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [
    RouterLink,
    TranslateModule,
    DropdownModule,
    SidebarModule,
    FormsModule,
    CommonModule,
  ],
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  backgroundColor: string = 'white';
  sidebarVisible: boolean = false;
  token: string | null = localStorage.getItem('token');

  menu = [
    {
      name: 'Cofanetti',
      link: '/regala-esperienza-in-ferrari-e-lamborghini',
    },
    {
      name: 'Driving Experience',
      link: '/esperienza-di-guida',
    },
    {
      name: 'Gift Card',
      link: '/gift-card',
    },
    {
      name: 'Noleggio',
      link: '/noleggio',
    },
  ];

  lingua = [
    {
      name: "Italiano",
      code: "it", //fatto
      iso: "IT",
      route: ""
    },
    {
      name: 'English',
      code: 'en', //fatto
      iso: 'GB',
      route: 'en',
    },
    {
      name: 'Spagnolo',
      code: 'es',
      iso: 'ES',
      route: 'es',
    },
    {
      name: 'Français',
      code: 'fr',
      iso: 'FR',
      route: 'fr',
    },
    {
      name: 'Deutsch',
      code: 'de',
      iso: 'DE',
      route: 'de',
    },
    {
      name: 'Mandarino',
      code: 'md', //fatto
      iso: 'CN',
      route: 'zh',
    },
    {
      name: 'Cirillico',
      code: 'pl',
      iso: 'RU',
      route: 'ru',
    },
    /*    {
      name: "Arabo",
      code: "ae", //fatto
      iso: "AE"
    },*/
  ];

  selectedCountry: any = this.lingua[0];
  cartItemCount$!: Observable<any>;
  constructor(
    private router: Router,
    private translateService: TranslateService,
    private store: Store
  ) {}

  ngOnInit() {
    const session = localStorage.getItem('token');
    if (session) {
      this.store.dispatch(CartActions.loadCartItems());
      this.cartItemCount$ = this.store.select(
        CartSelectors.selectCartItemCount
      );
    }

    // Ottiene il primo segmento del pathname
    const pathArray = window.location.pathname.split('/');
    const languageSegment = pathArray[1]; // Questo presuppone che il codice della lingua sia sempre il primo segmento

    // Trova la corrispondenza nella tua array di lingue
    const matchingLanguage = this.lingua.find(
      (lang) => lang.route === languageSegment
    );

    // Se trova una corrispondenza, imposta selectedCountry a quella lingua
    if (matchingLanguage) {
      this.selectedCountry = matchingLanguage;
    }
  }
  selectLingua(lingua: any) {
    const currentUrl = window.location.href;
    const baseUrl = currentUrl.split('/')[2]; // Ottiene l'host, es. zendesk.wecanrace.it
    const pathArray = window.location.pathname.split('/');
    if (pathArray[1].match(/^(it|en|de|fr|es|zh|ru)$/)) {
      // Se il primo segmento del path è una lingua, sostituiscilo
      pathArray[1] = lingua.value.route;
    } else {
      // Altrimenti, inserisci la lingua selezionata come primo segmento del path
      pathArray.splice(1, 0, lingua.value.route);
    }
    const newPath = pathArray.join('/');
    window.location.href = window.location.protocol + '//' + baseUrl + newPath;
  }
}
