import { Injectable } from '@angular/core';
import { Api } from '../provider/api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RecensioniService extends Api {


  getRecensioni(): Observable<any> {
    return this.http.get(this.getBaseUrl() + 'recensioni/list');
  }
}
