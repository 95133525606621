import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(): boolean {
    const isMaintenance = false; // Imposta questo in base al tuo controllo di manutenzione
    if (isMaintenance) {
      this.router.navigate(['/manutenzione']); // Assicurati di avere una route configurata per questo
      return false;
    }
    return true;
  }
}
