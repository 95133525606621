import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { LoadingService } from 'src/app/shared/components/spinner/loading.service';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isLoading$ = this.isLoadingSubject.asObservable();

  private subscription: Subscription;
  msgError: boolean = false
  constructor(private loadingService: LoadingService) {
    this.subscription = this.loadingService.responseObservable.subscribe(response => {
      if (response !== '') {
        this.hide();
        this.msgError = true
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  show() {
    this.isLoadingSubject.next(true);
  }

  hide() {
    this.isLoadingSubject.next(false);
  }
}
