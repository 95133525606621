import {Component, OnInit} from '@angular/core';
import {Calendario} from "../calendario/calendario.component";
import {DrivingExperienceService} from "../../core/driving-experience/driving-experience.service";
import {Meta, Title} from "@angular/platform-browser";
import {AnimationOptions} from "ngx-lottie";
import {AnimationItem} from "lottie-web";
import {RouterLink} from "@angular/router";
import {CurrencyPipe, NgClass, NgForOf, NgIf} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {MapItalyComponent} from "../shared/components/map-italy/map-italy.component";
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-circuiti',
  standalone: true,
  templateUrl: './circuiti.component.html',
  imports: [
    RouterLink,
    CurrencyPipe,
    NgForOf,
    TranslateModule,
    NgIf,
    NgClass,
    MapItalyComponent
  ],
  styleUrls: ['./circuiti.component.scss']
})
export class CircuitiComponent implements OnInit{

  regions: any[] = [];
  selectedRegion: any | undefined;
  selectedCircuit: any | null = null;
  circuitSelected: number | null = null;
  calendar: Calendario[] = [];
  calendarFiltered: Calendario[] = [];
  regione: any;
  circuitiMobile: any[] = [];
  image = environment.imageUrl

  listaCircuiti: any[] = []

  esperienze = [
    {
      titolo: 'driving experience classica',
    },
    {
      titolo: 'driving experience classica',
    }
  ]

  servizi = [
    {
      "titolo": "Parcheggio",
      "svg": "assets/svg/8.svg"
    },
    {
      "titolo": "Accesso alla pit-lane",
      "svg": "assets/svg/9.svg"
    },
    {
      "titolo": "Rinfresco open bar",
      "svg": "assets/svg/10.svg"
    },
    {
      "titolo": "Corso Briefing teorico",
      "svg": "assets/svg/11.svg"
    },
    {
      "titolo": "Giro di Ricognizione",
      "svg": "assets/svg/12.svg"
    },
    {
      "titolo": "Guida della supercar scelta",
      "svg": "assets/svg/13.svg"
    },
    {
      "titolo": "Pista in esclusiva",
      "svg": "assets/svg/14.svg"
    },
    {
      "titolo": "Assicurazione KasKo ed RC",
      "svg": "assets/svg/15.svg"
    },
    {
      "titolo": "Carburante",
      "svg": "assets/svg/16.svg"
    },
    {
      "titolo": "Cambio data Gratuito (max 30 gg prima)",
      "svg": "assets/svg/17.svg"
    },
    {
      "titolo": "Attestato di partecipazione",
      "svg": "assets/svg/18.svg"
    },
    {
      "titolo": "Gadget WCR",
      "svg": "assets/svg/19.svg"
    }
  ]

  options: AnimationOptions = {
    path: '/assets/animations/scroll.json',
  };

  constructor(private driveExperienceService: DrivingExperienceService,
              private title: Title,
              private meta: Meta) {
    title.setTitle('Circuiti | World Circuit Rent');
    meta.addTags([
      {name: 'description', content: 'Circuiti World Circuit Rent'},
      {name: 'keywords', content: 'Circuiti, World Circuit Rent'}
    ]);
  }

  ngOnInit() {
    this.getCircuiti()
    this.getCircuitiPrezzi()
  }

  handleRegionSelect(region: any): void {
    this.circuitSelected = null;
    const regione = this.regions.find(item => item.idregione == +region.idRegione);
    console.log(regione)
    this.regione = regione
    if (regione === undefined) {
      this.selectedRegion = {
        regione: 'Nessun circuito',
        circuits: []
      };
      this.calendarFiltered = [];
    } else {
      this.selectedRegion = regione;
      const risultatiFiltrati = this.calendar.filter((risultato) => risultato.eventi.some((evento) => evento.idRegione == +region.idRegione));

      this.calendarFiltered = risultatiFiltrati.map((risultato) => ({
        mese: risultato.mese,
        event_count: risultato.event_count,
        eventi: risultato.eventi.filter((evento) => evento.idRegione == +region.idRegione)
      }));
    }
  }

  selectCircuito(circuit: any): void {
    this.circuitSelected = circuit.idcircuito;
    this.selectedCircuit = circuit;

    const risultatiFiltrati = this.calendar.filter((risultato) => risultato.eventi.some((evento) => evento.idCircuito == +circuit.idcircuito));

    this.calendarFiltered = risultatiFiltrati.map((risultato) => ({
      mese: risultato.mese,
      event_count: risultato.event_count,
      eventi: risultato.eventi.filter((evento) => evento.idCircuito == circuit.idcircuito)
    }));
  }

  getCircuiti() {
    this.driveExperienceService.getCircuiti().subscribe((data) => {
      this.regions = data.data;
    })
  }

  getCircuitiPrezzi() {
    this.driveExperienceService.getCircuitiPrezzi().subscribe((data) => {
      this.listaCircuiti = data.data;
    })
  }

  goTo(circuito: string) {
    const get = document.getElementById(circuito);
    if (get) {
      const offset = get.offsetTop - 100;
      window.scrollTo({top: offset, behavior: 'smooth'});
    }
  }

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }

}
