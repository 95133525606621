import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GTrackingService {
  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }


  pushToDataLayer(event: string, ecommerceObj: any) {
    const user = JSON.parse(localStorage.getItem('userData') || '{}')
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.text = `
      dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
      dataLayer.push({
        customerFirstName: "${user.nome}",
        customerLastName: "${user.cognome}",
        customerEmail: "${user.email}",
        customerPhone: "${user.cellulare}",
        event: "${event}",
        ecommerce: ${JSON.stringify(ecommerceObj)}
      });
    `;
    this.renderer.appendChild(document.body, script);
    console.log('PIXEL SEND ', event, ecommerceObj);

    console.log(script.text)
  }





  controllaAcquisto: string = ''

}
