import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { CarrelloService } from "../../../../core/carrello/carrello.service";
import { Router, RouterLink } from "@angular/router";
import { CheckboxModule } from "primeng/checkbox";
import { AutoCompleteModule } from "primeng/autocomplete";
import { InputTextModule } from "primeng/inputtext";
import { TranslateModule } from "@ngx-translate/core";
import { CommonModule, NgIf } from "@angular/common";
import { RecensioniComponent } from '../recensioni/recensioni.component';

export interface FooterLink {
  href: string;
  text: string;
}

export interface FooterColumn {
  title: string;
  links: FooterLink[];
}

export interface FooterData {
  columns: FooterColumn[];
}

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [
    CheckboxModule,
    AutoCompleteModule,
    InputTextModule,
    ReactiveFormsModule,
    TranslateModule,
    FormsModule,
    RouterLink,
    CommonModule,
    RecensioniComponent,
  ],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  private dropdownStates: { [title: string]: boolean } = {};
  footerData: FooterData = {
    columns: [
      {
        title: 'MENU',
        links: [
          { href: '/circuiti', text: 'Circuiti e Date' },
          { href: '/calendario', text: 'Calendario eventi' },
          { href: '/', text: 'Le ns. Supercar' },
          { href: '/regala-esperienza-in-ferrari-e-lamborghini', text: 'Regala un cofanetto' },
          { href: '/gift-card', text: 'Regala una gift card' },
          { href: '/privacy-policy', text: 'Privacy Policy' },
          { href: '/cookie-policy', text: 'Cookie Policy' },
          { href: '/cookie-policy', text: 'Gestione Consenso Cookie' },
        ]
      },
      {
        title: 'ESPERIENZE',
        links: [
        
          { href: '/circuiti', text: 'Guida in pista una Supercar', },
          { href: '/circuiti', text: 'Guida Ferrari e Lamborghini', },
          { href: 'https://track-days.it/', text: 'Track days', },
          { href: '/eventi-aziendali', text: 'Eventi Aziendali Incentive' },
          { href: '/acquista-video', text: 'Video Ricordo' },
        ]
      },
      {
        title: 'NOLEGGIO',
        links: [
          { href: '/noleggio-ferrari-lamborghini-per-matrimonio-cerimonia', text: 'NOLEGGIO PER MATRIMONIO', },
          { href: '/noleggio-per-foto-shooting', text: 'NOLEGGIO PER FOTO E VIDEO', },
          { href: '/noleggio-per-foto-shooting', text: 'SHOOTING', },
          { href: '/noleggio-simulatore', text: 'NOLEGGIO SIMULATORE', },
          { href: '/', text: 'NOLEGGIO WEEK-END', },
          { href: '/', text: 'NOLEGGIO BREVE TERMINE', },
          { href: '/', text: 'NOLEGGIO LUNGO TERMINE', },
        ]
      },
      {
        title: 'PRENOTAZIONI',
        links: [
          { href: '/account/le-mie-esperienze', text: 'PRENOTAZIONE DATA', },
          { href: '/attivazione-cofanetto', text: 'ATTIVAZIONE COFANETTO', },
         
        ]
      },
      {
        title: '... SU DI NOI',
        links: [
          { href: '/chi-siamo', text: 'CHI SIAMO ❤️', },
          { href: '/', text: 'PERCHÈ NOI?', },
          { href: '/', text: 'BLOG E NEWS', },
          
          { href: '/lascia-una-recensione', text: 'RECENSIONI', },
          { href: '/', text: 'FOTO EVENTI', },
          { href: '/', text: 'VIDEO INTERVISTE', },
          { href: '/faq', text: 'FAQ', },
          { href: '/collabora-con-noi', text: 'COLLABORA CON NOI', },
        ]
      },
      {
        title: 'CONTATTI',
        links: [
          { href: '/contattaci', text: 'CONTATTI', },
          { href: 'mailto:reclami@wecanrace.it', text: 'FAI UN RECLAMO - DILLO AL CAPO!', },
          { href: 'https://wa.me/393899354924', text: 'WHATSAPP: 389.93.54.924', },
          { href: 'tel:04341710412', text: 'TELEFONO: 0434.17.10.412', },
          { href: 'mailto:help@wecanrace.it', text: 'E-MAIL: INFO@WECANRACE.IT', },
          
        ]
      },
    ]
  };


  form: any;
  openModal: boolean = false;
  isLgScreen = false;

  // Provincia
  province: any[] = [];
  filteredProvincia: any;
  selectedProvincia: any;
  send: boolean = false;

  constructor(private carrelloService: CarrelloService, private router: Router) {
  }
  highlightedTitle: string = 'PRENOTAZIONI';


  //STRUTTURA ARRAY
  col1: { key: string, value: any }[] = [];
  col2: { key: string, value: any }[] = [];
  col3: { key: string, value: any }[] = [];
  col4: { key: string, value: any }[] = [];
  col5: { key: string, value: any }[] = [];
  col6: { key: string, value: any }[] = [];
  col7: { key: string, value: any }[] = [];
  windowWidth: number = 0;
  ngOnInit(): void {
    this.windowWidth = window.innerWidth;  // Inizializza con la larghezza corrente della finestra
    console.log('SCHERMO: ', this.windowWidth);
    
    //DICHIARO
    this.col1 = [
      { key: '/circuiti', value: 'Circuiti e Date' },
      { key: '/calendario', value: 'Calendario eventi' },
      { key: '/', value: 'Le ns. Supercar' },
      { key: '/regala-esperienza-in-ferrari-e-lamborghini', value: 'Regala un cofanetto' },
      { key: '/gift-card', value: 'Regala una gift card' },
      { key: '/privacy-policy', value: 'Privacy Policy' },
      { key: '/cookie-policy', value: 'Cookie Policy' },
      { key: '/cookie-policy', value: 'Gestione Consenso Cookie' },
    ];



    this.col2 = [
      { key: '/circuiti', value: 'Guida in pista una Supercar', },
      { key: '/circuiti', value: 'Guida Ferrari e Lamborghini', },
      { key: 'https://track-days.it/', value: 'Track days', },
      { key: '/eventi-aziendali', value: 'Eventi Aziendali Incentive' },
      { key: '/acquista-video', value: 'Video Ricordo' },
    ];

    this.col3 = [
      { key: '/noleggio-ferrari-lamborghini-per-matrimonio-cerimonia', value: 'NOLEGGIO PER MATRIMONIO', },
      { key: '/noleggio-per-foto-shooting', value: 'NOLEGGIO PER FOTO E VIDEO', },
      { key: '/noleggio-per-foto-shooting', value: 'SHOOTING', },
      { key: '/', value: 'NOLEGGIO WEEK-END', },
      { key: '/', value: 'NOLEGGIO BREVE TERMINE', },
      { key: '/', value: 'NOLEGGIO LUNGO TERMINE', },
      { key: '/noleggio-simulatore', value: 'Noleggio simulatore', },
    ];

    this.col4 = [
      { key: '/account/le-mie-esperienze', value: 'PRENOTAZIONE DATA', },
      { key: '/attivazione-cofanetto', value: 'ATTIVAZIONE COFANETTO', },
    ];

    this.col5 = [
      { key: '/chi-siamo', value: 'CHI SIAMO ❤️', },
      { key: '/', value: 'PERCHÈ NOI?', },
      { key: '/', value: 'BLOG E NEWS', },
      { key: '/lascia-una-recensione', value: 'RECENSIONI', },
      { key: '/', value: 'FOTO EVENTI', },
      { key: '/', value: 'VIDEO INTERVISTE', },
      { key: '/faq', value: 'FAQ', },
      { key: '/collabora-con-noi', value: 'COLLABORA CON NOI', },
    ];

    this.col6 = [
      { key: '/contattaci', value: 'Contattaci', },
      { key: 'mailto:reclami@wecanrace.it', value: 'FAI UN RECLAMO - DILLO AL CAPO!', },
      { key: 'https://wa.me/393899354924', value: 'WHATSAPP: 389.93.54.924', },
      { key: 'tel:04341710412', value: 'TELEFONO: 0434.17.10.412', },
      { key: 'mailto:help@wecanrace.it', value: 'INFO@WECANRACE.IT', },
    ];

    this.col7 = [
      
    ];



    //FINE ARRAY
    
    this.initForm();
  }

  //Cambio di rotta per path
  navigateTo(route: string): void {
    this.router.navigate([route]);
  }

  navigateHref(route: string): void {
    window.location.href = route;
  }
  


    //Estrapolo la larghezza dello schermo (dall'evento)
    @HostListener('window:resize', ['$event'])
    onResize(event: Event) {
      this.windowWidth = (event.target as Window).innerWidth;
      console.log('Larghezza aggiornata:', this.windowWidth);
    }
    // fine funzione


    flagCol: number | null = null;
    onClickFlag(index: number) {
      this.flagCol = this.flagCol === index ? null : index;
      console.log(`Col ${index + 1} VIEW: ${this.flagCol === index}`);
    }





















  //checkScreenSize() {
  //  this.isLgScreen = window.innerWidth >= 1024; // Adjust the breakpoint as needed
  //}

  initForm() {
    this.form = new FormGroup({
      nome: new FormControl('', Validators.required),
      provincia: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      privacy: new FormControl('', Validators.required),
    });

    this.carrelloService.statiRegioniProvince().subscribe(res => {
      this.province = res.data.province;
    });
  }

  toggleDropdown(column: FooterColumn) {
    this.dropdownStates[column.title] = !this.dropdownStates[column.title];
  }

  isDropdownOpen(column: FooterColumn): boolean {
    return this.dropdownStates[column.title] ?? false;
  }






  closeModal() {
    this.send = false;
    this.form.reset();
    this.openModal = false;
  }

  filterProvincia($event: any) {
    let filtered: any[] = [];
    let query = $event.query;
    for (let i = 0; i < this.province.length; i++) {
      let list = this.province[i];
      if (list.nome.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(list);
      }
    }
    this.filteredProvincia = filtered;
  }

  sendNewsLetter() {
    this.send = true;
    this.carrelloService.newsLetter(this.form.value).subscribe(res => {
      this.openModal = true;
      this.form.reset();
    })
  }
}
