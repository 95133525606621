import {Inject, Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {DOCUMENT} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2, @Inject(DOCUMENT) private document: Document) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  loadScript(scriptUrl: string, attrs: { [key: string]: string }, type?: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const script = this.renderer.createElement('script');
      if (type) script.setAttribute('type', type)
      script.src = scriptUrl;
      script.dataset.namespace = attrs['namespace'] || 'paypal';  // Set the namespace if provided
      Object.keys(attrs).forEach(key => {
        if (key !== 'namespace') {  // Avoid re-setting the namespace attribute
          script.setAttribute(key, attrs[key]);
        }
      });
      script.onload = resolve;
      script.onerror = reject;
      this.renderer.appendChild(this.document.head, script);
    });
  }
}
