import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { PrimeNGConfig } from "primeng/api";
import { Store } from "@ngrx/store";
import * as CartActions from "../core/carrello/action/cart.action";
import * as CartSelectors from "../core/carrello/reducer/cart.selectors";
import { Observable } from "rxjs";
import { CarrelloService } from "../core/carrello/carrello.service";
import { AuthService } from "../core/auth/auth.service";
import { environment } from "../environments/environment";
import { PaymentService } from "../core/payment/payment.service";
import { DrivingExperienceService } from 'src/core/driving-experience/driving-experience.service';

// NO INDEX
import { Meta } from '@angular/platform-browser';
// NO INDEX




@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy, OnInit {
  title = 'wcr-ecommerce';

  lang = {
    startsWith: "Inizia con",
    contains: "Contiene",
    notContains: "Non contiene",
    endsWith: "Finisce con",
    equals: "Uguale a",
    notEquals: "Diverso da",
    noFilter: "Nessun filtro",
    lt: "Minore di",
    lte: "Minore o uguale a",
    gt: "Maggiore di",
    gte: "Maggiore o uguale a",
    is: "È",
    isNot: "Non è",
    before: "Prima di",
    after: "Dopo",
    dateIs: "La data è",
    dateIsNot: "La data non è",
    dateBefore: "La data è prima di",
    dateAfter: "La data è dopo",
    clear: "Pulisci",
    apply: "Applica",
    matchAll: "Corrispondi tutti",
    matchAny: "Corrispondi uno qualsiasi",
    addRule: "Aggiungi regola",
    removeRule: "Rimuovi regola",
    accept: "Sì",
    reject: "No",
    choose: "Scegli",
    upload: "Carica",
    cancel: "Annulla",
    dayNames: ["Domenica", "Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato"],
    dayNamesShort: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
    dayNamesMin: ["Do", "Lu", "Ma", "Me", "Gi", "Ve", "Sa"],
    monthNames: ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"],
    monthNamesShort: ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago", "Set", "Ott", "Nov", "Dic"],
    dateFormat: "dd/mm/yy",
    firstDayOfWeek: 0,
    today: "Oggi",
    weekHeader: "Sett",
    weak: "Debole",
    medium: "Medio",
    strong: "Forte",
    passwordPrompt: "Inserisci una password",
    emptyMessage: "Nessun risultato trovato",
    emptyFilterMessage: "Nessun risultato trovato"
  }
  exitIntentTimeout: any; // Variabile per gestire il timeout
  exitIntent = false; // Variabile per gestire il timeout




  options: any[] = [
    {
      label: 'Sono bloccato/a per un problema tecnico',
      value: 'Sono bloccato/a per un problema tecnico'
    },
    {
      label: 'Il codice sconto non funziona',
      value: 'Il codice sconto non funziona'
    },
    {
      label: 'Non sono ancora convinto/a ed ho bisogno di farvi delle domande',
      value: 'Non sono ancora convinto/a ed ho bisogno di farvi delle domande'
    },
    {
      label: 'Non mi fido di voi',
      value: 'Non mi fido di voi'
    },
    {
      label: 'Non sono ancora deciso di fare questo acquisto',
      value: 'Non sono ancora deciso di fare questo acquisto'
    },
    {
      label: 'Altro',
      value: 'Altro'
    }
  ];
  selectedOptions: any;
  otherReason: any;
  cartItemCount$!: Observable<number>;
  count: number = 0;
  submitted: boolean = false;
  profile: any = {};

  constructor(private primengConfig: PrimeNGConfig,
    private store: Store,
    private authService: AuthService,
    private paymentService: PaymentService,
    private carrelloService: CarrelloService,
    private activeRoute: ActivatedRoute,
    private serv: DrivingExperienceService,
    private meta: Meta,

    private router: Router,) {

  }

  @HostListener('document:mouseleave', ['$event'])
  onMouseLeave(event: MouseEvent): void {

    if (environment.production) {
      setTimeout(() => {

        const getExitIntent = sessionStorage.getItem('ei');
        if (getExitIntent === 'true') {
          this.exitIntent = false;
          return;
        }

        const carrello = JSON.parse(localStorage.getItem('carrello') || '[]');
        if (carrello.length > 0 || this.count > 0) {
          if (!this.exitIntent) {
            this.exitIntent = true;
            sessionStorage.setItem('ei', 'true');
          }
        }

      }, 22000);
    }
  }



  //Forzatura dal componente Master
  //Forzatura dal componente Master
  //Forzatura dal componente Master
  //Forzatura dal componente Master


  slugCircuito = ''
  slugVeicolo = ''
  ngOnInit(): void {
    // NO INDEX
    this.meta.updateTag({ name: 'robots', content: 'noindex, nofollow' });
    // NO INDEX



    this.activeRoute.params.subscribe(params => {
      console.log(params)
      this.slugVeicolo = params['veicolo']
      this.slugCircuito = params['slug']

      // setTimeout(() => {
      //   console.log('Serv asd circuito: ', this.serv.cir);
      //   console.log('Serv asd veicolo: ', this.serv.vei);
      //   this.router.navigate([`/circuiti/${this.serv.cir}/${this.serv.vei}`]);
      // }, 800)


      console.log(" ROUTING Parametri ricevuti:", params);
      console.log(" ROUTING Slug Veicolo:", params['veicolo']);
      console.log(" ROUTING Slug Circuito:", params['slug']);
    })



    const session = localStorage.getItem('token');
    if (session) {
      this.authService.getProfile().subscribe((res) => {
        console.log(res);
        this.profile = res.data
        localStorage.setItem('userData', JSON.stringify(res.data))
      })
      this.store.dispatch(CartActions.loadCartItems());
      this.cartItemCount$ = this.store.select(CartSelectors.selectCartItemCount);
      this.cartItemCount$.subscribe(res => {
        this.count = res;
      })
    }





    //Forzatura dal componente Master
    //Forzatura dal componente Master
    //Forzatura dal componente Master
    //Forzatura dal componente Master



    sessionStorage.setItem('ei', 'false'); // exit intent
    this.primengConfig.setTranslation(this.lang);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });
  }





  ngOnDestroy(): void {
    clearTimeout(this.exitIntentTimeout);
  }

  sendFeed() {
    let motivo: string;
    if (this.selectedOptions === 'Altro') {
      motivo = this.otherReason;
    } else {
      motivo = this.selectedOptions;
    }

    const params = {
      idAnagrafica: this.profile.id || null,
      motivo: motivo,
      url: this.router.url,
      carrello: JSON.parse(localStorage.getItem('carrello') || '[]')
    }

    this.submitted = false;
    this.exitIntent = false;
    this.carrelloService.sendFeed(params).subscribe(res => {
      if (res.status === 'success') {
        this.selectedOptions = '';
        this.otherReason = '';

      }
    });
  }
}
