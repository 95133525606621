<app-spinner></app-spinner>
<router-outlet></router-outlet>


<div class="relative z-[9999999999999]" aria-labelledby="modal-title" role="dialog" aria-modal="true" *ngIf="exitIntent">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">

      <div
        class="h-[75vh] overflow-y-auto max-sm:h-[90vh] sm:w-[60%] relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 max-sm:w-[98%] sm:p-6">
        <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
          <button type="button" (click)="sendFeed()"
                  class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            <span class="sr-only">Close</span>
            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                 aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/>
            </svg>
          </button>
        </div>
        <div class="sm:flex  items-center justify-center sm:items-start">
          <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title"></h3>
            <div class="mt-2">

              <!--<div class="flex flex-row items-center justify-center">

                <img src="/assets/images/logo.png" alt="">

              </div>
-->
              <div class="flex flex-col my-6">

                <h3 class="aviano-bold text-3xl text-center">Vuoi dirci perchè vai via?</h3>

                <!-- POP UP  -->
                <div class="box overflow-y-auto max-h-[600px]">

                  <fieldset>

                    <div class="radio my-3 p-5 bg-gray-200 hover:bg-[#CC0000] hover:text-white" *ngFor="let item of options">
                      <p-radioButton [value]="item.value" [(ngModel)]="selectedOptions"
                                     name="category"></p-radioButton>
                      <label class="radio-label ml-4">{{item.label}}</label>
                    </div>

                    <div class="radio my-3 p-5 bg-gray-200 hover:bg-[#CC0000] hover:text-white" *ngIf="selectedOptions === 'Altro'">
                      <input
                        type="text"
                        class="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                        placeholder="Altro"
                        [(ngModel)]="otherReason"
                      >
                    </div>

                  </fieldset>

                </div>

                <p class="aviano-thin py-4 text-center">Se hai <strong>problemi tecnici</strong> contatta gli sviluppatori a questo numero
                  <strong class="underline"><a href="tel:3533328416">3533328416</a></strong> <br> ti risponderà l'ufficio informatico che <strong>non saprà darti informazioni in merito a prenotazioni ma solo su malfunzionamenti</strong></p>


                <button class="btn-primary" (click)="sendFeed()">
                  Invia
                </button>

              </div>
              <!-- POP UP END -->




            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
