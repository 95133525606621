
<div class="flex justify-center">
  <div class="w-2/4 h-0.5 bg-red-500"></div>
</div>

<div class="overflow-hidden text-center py-12 px-4">
  <div class="flex flex-col justify-center items-center mb-4">
    <div class="flex items-center">
      <h1 class="aviano-bold text-lg md:text-3xl lg:text-5xl">Dicono di noi...</h1>
      <div class="flex ml-4">
        <svg class="w-6 h-6 text-yellow-500" fill="currentColor" viewBox="0 0 20 20">
          <path
            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.234 3.773a1 1 0 00.95.69h3.977c.969 0 1.372 1.24.588 1.81l-3.21 2.356a1 1 0 00-.364 1.118l1.234 3.772c.3.922-.755 1.688-1.54 1.118l-3.21-2.356a1 1 0 00-1.176 0l-3.21 2.356c-.784.57-1.838-.196-1.54-1.118l1.234-3.772a1 1 0 00-.364-1.118L2.83 9.2c-.784-.57-.38-1.81.588-1.81h3.977a1 1 0 00.95-.69l1.234-3.773z">
          </path>
        </svg>
        <svg class="w-6 h-6 text-yellow-500" fill="currentColor" viewBox="0 0 20 20">
          <path
            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.234 3.773a1 1 0 00.95.69h3.977c.969 0 1.372 1.24.588 1.81l-3.21 2.356a1 1 0 00-.364 1.118l1.234 3.772c.3.922-.755 1.688-1.54 1.118l-3.21-2.356a1 1 0 00-1.176 0l-3.21 2.356c-.784.57-1.838-.196-1.54-1.118l1.234-3.772a1 1 0 00-.364-1.118L2.83 9.2c-.784-.57-.38-1.81.588-1.81h3.977a1 1 0 00.95-.69l1.234-3.773z">
          </path>
        </svg>
        <svg class="w-6 h-6 text-yellow-500" fill="currentColor" viewBox="0 0 20 20">
          <path
            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.234 3.773a1 1 0 00.95.69h3.977c.969 0 1.372 1.24.588 1.81l-3.21 2.356a1 1 0 00-.364 1.118l1.234 3.772c.3.922-.755 1.688-1.54 1.118l-3.21-2.356a1 1 0 00-1.176 0l-3.21 2.356c-.784.57-1.838-.196-1.54-1.118l1.234-3.772a1 1 0 00-.364-1.118L2.83 9.2c-.784-.57-.38-1.81.588-1.81h3.977a1 1 0 00.95-.69l1.234-3.773z">
          </path>
        </svg>
        <svg class="w-6 h-6 text-yellow-500" fill="currentColor" viewBox="0 0 20 20">
          <path
            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.234 3.773a1 1 0 00.95.69h3.977c.969 0 1.372 1.24.588 1.81l-3.21 2.356a1 1 0 00-.364 1.118l1.234 3.772c.3.922-.755 1.688-1.54 1.118l-3.21-2.356a1 1 0 00-1.176 0l-3.21 2.356c-.784.57-1.838-.196-1.54-1.118l1.234-3.772a1 1 0 00-.364-1.118L2.83 9.2c-.784-.57-.38-1.81.588-1.81h3.977a1 1 0 00.95-.69l1.234-3.773z">
          </path>
        </svg>
        <svg class="w-6 h-6 text-yellow-500" fill="currentColor" viewBox="0 0 20 20">
          <path
            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.234 3.773a1 1 0 00.95.69h3.977c.969 0 1.372 1.24.588 1.81l-3.21 2.356a1 1 0 00-.364 1.118l1.234 3.772c.3.922-.755 1.688-1.54 1.118l-3.21-2.356a1 1 0 00-1.176 0l-3.21 2.356c-.784.57-1.838-.196-1.54-1.118l1.234-3.772a1 1 0 00-.364-1.118L2.83 9.2c-.784-.57-.38-1.81.588-1.81h3.977a1 1 0 00.95-.69l1.234-3.773z">
          </path>
        </svg>
      </div>
    </div>
    <h2 class="aviano-bold text-sm md:text- lg:text-2xl">Oltre 2500 recensioni da profili REALI… Chiedi a loro!</h2>


  </div>
  <div class="swiper-cont">
    <div class="swiper-wrapper">
      <div class="swiper-slide" data-swiper-autoplay="8000" *ngFor="let recensione of recensioni | slice:0:4">
        <a [href]="recensione.author_url" target="_blank" rel="noopener noreferrer" class="block">
          <div class="relative w-full rounded overflow-hidden shadow-lg bg-gray-50 p-4">
            <div>
              <img src="../../../../assets/icon/google.webp" alt="Google Logo"
                   class="absolute top-0.5 left-0.5 w-8 h-8">
            </div>
            <div class="flex flex-row justify-center items-center">
              <div class="flex flex-col justify-center items-center w-[60rem]">
                <img class="w-12 h-12 rounded-full" src="{{recensione.profile_photo_url}}" alt="Avatar del recensore">
                <p class="text-gray-900 rajdhani-semibold text-sm leading-none mt-2">
                  {{ recensione.author_name }}
                </p>
                <div class="flex mt-2">
                  <ng-container *ngFor="let star of [1, 2, 3, 4, 5]; let i = index">
                    <svg *ngIf="i < recensione.rating" class="w-5 h-5 text-yellow-500" fill="currentColor"
                         viewBox="0 0 20 20">
                      <path
                        d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.234 3.773a1 1 0 00.95.69h3.977c.969 0 1.372 1.24.588 1.81l-3.21 2.356a1 1 0 00-.364 1.118l1.234 3.772c.3.922-.755 1.688-1.54 1.118l-3.21-2.356a1 1 0 00-1.176 0l-3.21 2.356c-.784.57-1.838-.196-1.54-1.118l1.234-3.772a1 1 0 00-.364-1.118L2.83 9.2c-.784-.57-.38-1.81.588-1.81h3.977a1 1 0 00.95-.69l1.234-3.773z">
                      </path>
                    </svg>
                    <svg *ngIf="i >= recensione.rating" class="w-5 h-5 text-gray-300" fill="currentColor"
                         viewBox="0 0 20 20">
                      <path
                        d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.234 3.773a1 1 0 00.95.69h3.977c.969 0 1.372 1.24.588 1.81l-3.21 2.356a1 1 0 00-.364 1.118l1.234 3.772c.3.922-.755 1.688-1.54 1.118l-3.21-2.356a1 1 0 00-1.176 0l-3.21 2.356c-.784.57-1.838-.196-1.54-1.118l1.234-3.772a1 1 0 00-.364-1.118L2.83 9.2c-.784-.57-.38-1.81.588-1.81h3.977a1 1 0 00.95-.69l1.234-3.773z">
                      </path>
                    </svg>
                  </ng-container>
                </div>
              </div>
              <div class="h-[100px] bg-[#DAD6D6] w-[1px] mx-2"></div>
              <div class="text-sm">
                <p class="rajdhani-regular line-clamp-3 text-base text-gray-600">{{ recensione.text }}</p>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
    <ng-template #loading>
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <p-skeleton width="100%" height="200px"></p-skeleton>
        </div>
        <div class="swiper-slide">
          <p-skeleton width="100%" height="200px"></p-skeleton>
        </div>
        <div class="swiper-slide">
          <p-skeleton width="100%" height="200px"></p-skeleton>
        </div>
        <div class="swiper-slide">
          <p-skeleton width="100%" height="200px"></p-skeleton>
        </div>
        <div class="swiper-slide">
          <p-skeleton width="100%" height="200px"></p-skeleton>
        </div>
      </div>
    </ng-template>
  </div>

</div>
