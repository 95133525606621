import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { Api } from "../provider/api.service";
import * as CartActions from "./action/cart.action";

export interface Esperienza {
  id?: any;
  descrizione?: string,
  idEvento?: number,
  idVeicolo?: number,
  idCircuito?: number,
  idTurno?: number,
  idTipoGuida?: number,
  prezzo?: number,
  numeroPassegeri?: number,
  numeroGiri?: number,
  tipo?: 'esperienza';
  servizi: Servizi[],
}

export interface Servizi {
  descrizione?: string,
  idServizio?: number,
  quantita?: number,
  prezzo?: number,
  tipo?: string; // Aggiungi il campo "tipo" come opzionale
}

@Injectable({
  providedIn: 'root'
})
export class CarrelloService extends Api {

  addItemToCart(params: any /* sostituisci con il tipo appropriato */) {
    const token = localStorage.getItem('token');
    const tokenAdmin = sessionStorage.getItem('tokenAdmin');

    // Use tokenAdmin if it's available, otherwise use the regular token
    const activeToken = tokenAdmin || token;

    const event = 'add_to_cart';
    const ecommerceObj = {
      currency: 'EUR',
      value: params.prezzoTotale,
      items: [{
        item_id: Math.random().toString(36).substring(7),
        item_name: params.nome,
        item_brand: 'WeCanRace',
        item_variant: params.formato,
        price: params.prezzoTotale,
        quantity: 1
      }]
    };

    if (!activeToken) {
      console.log('token non presente')

      if (!localStorage.getItem('carrello')) {
        localStorage.setItem('carrello', JSON.stringify([]));
      }

      const carrelloValue = JSON.parse(localStorage.getItem('carrello') || '[]');
      carrelloValue.push(params);
      localStorage.setItem('carrello', JSON.stringify(carrelloValue));
      this.dataLayerService.pushToDataLayer(event, ecommerceObj);
      this.router.navigate(['/checkout']).then(res => {
        console.log('res', res)
      }).catch(err => {
        console.log('err', err)
      })
    } else {
      this.store.dispatch(CartActions.addToCart({ item: params }));


      this.dataLayerService.pushToDataLayer(event, ecommerceObj);
    }

  }

  addToCart(item: any) {
    return this.http.post(this.getBaseUrl() + 'cart/add', item);
  }

  getCartItems(): Observable<any> {
    return this.http.get(this.getBaseUrl() + 'cart/list');
  }

  removeCartItem(itemId: any): Observable<any> {
    return this.http.delete(this.getBaseUrl() + 'cart/' + itemId);
  }

  createOrder(payload: any): Observable<any> {
    return this.http.post(this.getBaseUrl() + 'cart/orders-two', payload);
  }

  approveOrder(params: any): Observable<any> {
    return this.http.put(this.getBaseUrl() + 'cart/orders/approvetwo', params);
  }

  statiRegioniProvince(): Observable<any> {
    return this.http.get(this.getBaseUrl() + 'store/stati-regioni-province');
  }

  newsLetter(value: any): Observable<any> {
    return this.http.post(this.getBaseUrl() + 'pabbly/newsletter', value);
  }

  applyCoupon(params: any): Observable<any> {
    return this.http.post(this.getBaseUrl() + 'cart/applica-coupon', params);
  }

  sendFeed(params: any): Observable<any> {
    return this.http.post(this.getBaseUrl() + 'cart/feedback-cart', params);
  }

  getRequestCarrello(token: string): Observable<any> {
    return this.http.get(this.getBaseUrl() + 'payment/scalapay/request/' + token);
  }

  getFailedOrderTransaction(idOrdine: number): Observable<any> {
    return this.http.get(this.getBaseUrl() + 'cart/detail-ordine-fallito/' + idOrdine);
  }

  setupPay(payload: any): Observable<any> {
    return this.http.post(this.getBaseUrl() + 'cart/setup-pay', payload)
  }


  itemsDima: any[] = []



}
