import {Injectable, PLATFORM_ID, Inject} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {CarrelloService} from "../carrello.service";
import {EMPTY, exhaustMap, mergeMap, of, switchMap} from "rxjs";
import {catchError, map} from "rxjs/operators";
import * as CarrelloActions from '../action/cart.action';
import {Router} from "@angular/router";
import * as CartActions from '../action/cart.action';
import {DrivingExperienceService} from "../../driving-experience/driving-experience.service";
import {GTrackingService} from "../../GoogleTrackig/g-tracking.service";  // sostituisci con il percorso corretto


@Injectable()
export class CartEffect {
  constructor(
    private actions$: Actions,
    private carrelloService: CarrelloService,
    private driveExperienceService: DrivingExperienceService,
    private dataLayerService: GTrackingService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
  }

  addToCart$ = createEffect(() => this.actions$.pipe(
    ofType(CartActions.addToCart),
    mergeMap(action => {
      console.log("Effetto addToCart attivato");  // Debug
      return this.carrelloService.addToCart(action.item)
        .pipe(
          map(() => {
            // verficia che l'url attuale non  sia /checkout
            if (this.router.url !== '/checkout') {
              this.router.navigate(['/checkout']);
            }
            this.driveExperienceService.svuotaEspeienzaGuida();
            return CartActions.addToCartSuccess({ item: action.item });
          }),
          catchError(error => {
            console.log("Errore nella chiamata API:", error);  // Debug
            return [CartActions.addToCartFailure({ error })];
          })
        )
    })
  ));

  removeFromCart$ = createEffect(() => this.actions$.pipe(
    ofType(CartActions.removeFromCart),
    mergeMap(action => {
      console.log("Effetto removeFromCart attivato");  // Debug
      return this.carrelloService.removeCartItem(action.itemId)
        .pipe(
          map(() => {
            console.log("Rimosso dal carrello", action.itemId);  // Debug
            return CartActions.removeFromCartSuccess({ itemId: action.itemId });
          }),
          catchError(error => {
            console.log("Errore nella chiamata API:", error);  // Debug
            return [CartActions.removeFromCartFailure({ error })];
          })
        )
    })
  ));

  loadCartItems$ = createEffect(() => this.actions$.pipe(
    ofType(CartActions.loadCartItems),
    mergeMap(() => {
      return this.carrelloService.getCartItems()
        .pipe(
          map((items: any) => {
            console.log(items)
            return CartActions.loadCartItemsSuccess({ items : items.data.carrello });
          }),
          catchError(error => {
            console.log("Errore nella chiamata API:", error);  // Debug
            return [CartActions.loadCartItemsFailure({ error })];
          })
        )
    })
  ));



}
