import {Component, OnInit} from '@angular/core';
import {Router, RouterOutlet} from "@angular/router";
import {AuthService} from "../../core/auth/auth.service";
import {NavbarComponent} from "../shared/components/navbar/navbar.component";
import {NgIf} from "@angular/common";
import {FooterComponent} from "../shared/components/footer/footer.component";
import {AuthComponent} from "../shared/components/auth/auth.component";

@Component({
  selector: 'app-layout',
  standalone: true,
  templateUrl: './layout.component.html',
  imports: [
    NavbarComponent,
    NgIf,
    RouterOutlet,
    FooterComponent,
    AuthComponent
  ],
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit{
  openModal: boolean = false
  backgroundColor: string = 'white';

  constructor(private router: Router, private authService: AuthService) {}

  checkRoute() {
    const currentUrl = this.router.url;
    return currentUrl === '/prenotazioni' || currentUrl.startsWith('/account');
  }

  ngOnInit() {
    this.authService.loginModalEvent$.subscribe((event: boolean) => {
      this.openModal = event; // Imposta lo stato del modal in base all'evento ricevuto
    });
  }
}
