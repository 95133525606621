// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //apiUrl: "https://api.wecanrace.it/api/",
  apiUrl: "https://back-staging.wecouldrace.com/api/",
  imageUrl: "https://back-staging.wecouldrace.com/static/",
  socket: "http://localhost:4000",
  stripe: {
    key: 'pk_test_YS4DFlzrReRztjOUCjAMC40M'
  },
  paypal: {
    key: 'AZjl0_id3hSF4JO7ggG2j1QbHiD90HKYAbGrGjASf7ZMZqrvk3IU6jQ8vbpxsH_GlRgnDQO26YG_b1AA'
  },
  google: {
    key: 'AIzaSyCUtCwQhcK3PEQ_Qo84XSpzPUPWSc5CXjY'
  }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
