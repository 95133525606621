import { Component } from '@angular/core';
import {SpinnerService} from "../../../../core/provider/spinner.service";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {CommonModule} from "@angular/common";

@Component({
  selector: 'app-spinner',
  standalone: true,
  templateUrl: './spinner.component.html',
  imports: [
    ProgressSpinnerModule,
    CommonModule,
  ],
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent {

  constructor(public spinnerService: SpinnerService) {
  }

}
