import { Injectable } from '@angular/core';
import { Api } from "../provider/api.service";
import { BehaviorSubject, Observable, Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthService extends Api {
  loginModalEvent$ = new BehaviorSubject<any>({ stato: false, form: 'login', text: '' });
  returnUrl$ = new BehaviorSubject<string>('');

  login(credentials: any): Observable<any> {
    return this.http.post(this.getBaseUrl() + 'auth/customer/login', credentials);
  }

  register(user: any): Observable<any> {
    return this.http.post(this.getBaseUrl() + 'auth/customer/register', user);
  }

  resetPassword(email: string): Observable<any> {
    return this.http.post(this.getBaseUrl() + 'auth/customer/reset-password', { email });
  }

  verifyOtp(otp: string, email: string): Observable<any> {
    return this.http.post(this.getBaseUrl() + 'auth/customer/verify-otp', { otp, email });
  }

  doubleOptin(otp: string, email: string): Observable<any> {
    return this.http.post(this.getBaseUrl() + 'auth/customer/double-optin', { otp, email });
  }

  changePassword(email: string, password: string, confermaPassword: string): Observable<any> {
    return this.http.post(this.getBaseUrl() + 'auth/customer/cambia-password', { email, password, confermaPassword });
  }

  getProfile(): Observable<any> {
    return this.http.get(this.getBaseUrl() + 'auth/customer/profilo');
  }




  getLocation(params: any): Observable<any> {
    return this.http.get<any>(this.getBaseUrl() + 'utility/location/', { params });
  }

  getAllLocation(): Observable<any> {
    return this.http.get<any>(this.getBaseUrl() + 'utility/all-location/');
  }




  getStati(): Observable<any> {
    return this.http.get<any>(this.getBaseUrl() + `utility/stato/list`);
  }

  updateProfile(params: any): Observable<any> {
    return this.http.patch<any>(this.getBaseUrl() + `auth/customer/update`, params);
  }

  updateCustomerPassword(params: any): Observable<any> {
    return this.http.patch<any>(this.getBaseUrl() + `auth/customer/update-password`, params);
  }

  verifyAccount(params: any): Observable<any> {
    return this.http.post(this.getBaseUrl() + 'auth/customer/verify-account', params);
  }

  sendLead(params: any): Observable<any> {
    return this.http.post(this.getBaseUrl() + 'pabbly/lead', params);
  }

  sendLeadCollaboraConNoi(params: any): Observable<any> {
    return this.http.post(this.getBaseUrl() + 'pabbly/lead-collabora', params);
  }
  sendLeadVoucher(formData: FormData): Observable<any> {
    return this.http.post(this.getBaseUrl() + 'pabbly/lead-voucher', formData);
  }
}
