import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from "@angular/forms";
import {Router} from "@angular/router";
import {AuthService} from "../../../../core/auth/auth.service";
import {catchError} from "rxjs/operators";
import {throwError} from "rxjs";
import {CommonModule, NgOptimizedImage} from "@angular/common";

@Component({
  selector: 'app-auth',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgOptimizedImage,
    FormsModule
  ],
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit, OnDestroy {
  form: any;
  isLoading = false;
  errore = '';
  success = '';
  typeForm: string = 'login';
  resetButtonDisabled: boolean = true;

  inputValues = {
    first: '',
    second: '',
    third: '',
    fourth: '',
    fifth: '',
    sixth: ''
  };
  changePassword: any;
  openModal: boolean = false;
  fieldTextType: boolean = false;
  loading: boolean = false;
  text: string = '';
  nome: string = '';
  resetRequestTime: any;
  remainingTime: null | number = null
  minutes: null | number = null
  seconds: null | number = null
  intervalId: any;
  constructor(
    private router: Router,
    private authService: AuthService,
  ) {
  }

  ngOnInit(): void {
    const storedData = localStorage.getItem('resetButtonData');
    if (storedData) {
      const { disabled, time } = JSON.parse(storedData);
      this.resetButtonDisabled = disabled;
      this.resetRequestTime = time;
      this.startCountdown();
    } else {
      this.resetButtonDisabled = false;
      this.resetRequestTime = null;
      this.remainingTime = null;
      this.minutes = null;
      this.seconds = null;
    }
    this.authService.loginModalEvent$.subscribe((event: any) => {
      this.openModal = event.stato; // Imposta lo stato del modal in base all'evento ricevuto
      this.typeForm = event.form
      this.text = event.text
    });
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', Validators.required),
      nome: new FormControl('', Validators.required),
      cognome: new FormControl('', Validators.required),
      telefono: new FormControl('', Validators.required),
      confermaPassword: new FormControl('', Validators.required),
      acceptPolicy: new FormControl(false, Validators.requiredTrue)
    })
    this.changePassword = new FormGroup({
      password: new FormControl('', Validators.required),
      confermaPassword: new FormControl('', Validators.required),
    }, {validators: this.passwordsMatchValidator})
    this.changeTypeForm('login');
  }

  passwordsMatchValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const password = control.get('password');
    const confermaPassword = control.get('confermaPassword');

    if (password && confermaPassword && password.value !== confermaPassword.value) {
      return {'passwordsMismatch': true};
    }

    return null;
  }

  handleAuth(): void {
    this.nome = this.form.value.nome;
    const {email, password, nome, cognome, telefono, confermaPassword} = this.form.value;

    if (this.typeForm === 'login') {
      this.isLoading = true;
      if (!email || !password) {
        this.errore = 'Inserisci i dati per accedere';
        this.isLoading = false;
        return;
      }

      this.authService.login({username: email, password}).pipe(
        catchError(error => {
          this.isLoading = false;
          this.errore = error.error.message;
          return throwError(error);
        })
      ).subscribe(response => {
        localStorage.setItem('token', response.data.token);
        this.authService.loginModalEvent$.next({stato: false, form: 'login'});
        const returnUrl = this.authService.returnUrl$.value;
        this.router.navigate([returnUrl]).then(r => {
          this.authService.returnUrl$.next('')
        });
      });

    }

    if (this.typeForm === 'register') {
      this.isLoading = true;
      if (!email || !password || !nome || !cognome || !telefono || !confermaPassword) {
        this.errore = 'Compila tutti i campi';
        this.isLoading = false;
        return;
      }

      this.authService.register({email, password, nome, cognome, telefono, confermaPassword}).pipe(
        catchError(error => {
          this.isLoading = false;
          this.errore = error.error.message;
          console.log(error)
          return throwError(error);
        })
      ).subscribe(response => {
        this.typeForm = 'double-optin'
        this.errore = '';
      });
    }

    if (this.typeForm === 'reset') {
      this.isLoading = true;
      this.authService.resetPassword(email).pipe(
        catchError(error => {
          this.isLoading = false;
          this.errore = error.error.message;
          return throwError(error);
        })
      ).subscribe(response => {
        this.resetButtonDisabled = true;
        this.resetRequestTime = Date.now();
        const data = { disabled: this.resetButtonDisabled, time: this.resetRequestTime };
        localStorage.setItem('resetButtonData', JSON.stringify(data));

        this.startCountdown();


        this.typeForm = 'otp'
        this.errore = '';
      });
    }
  }

  startCountdown() {
    const currentTime = Date.now();
    const elapsedTime = currentTime - this.resetRequestTime;
    const threeMinutesInMillis = 3 * 60 * 1000;
    const remainingTimeInMillis = threeMinutesInMillis - elapsedTime;

    if (remainingTimeInMillis > 0) {
      this.remainingTime = Math.ceil(remainingTimeInMillis / 1000); // Tempo rimanente in secondi

      this.intervalId = setInterval(() => {
        this.remainingTime!--;
        this.minutes = Math.floor(this.remainingTime! / 60);
        this.seconds = this.remainingTime! % 60;

        if (this.remainingTime! <= 0) {
          clearInterval(this.intervalId);
          this.resetButtonDisabled = false;
          this.resetRequestTime = null;
          this.remainingTime = null;
          this.minutes = null;
          this.seconds = null;
          localStorage.removeItem('resetButtonData');
        }
      }, 1000); // Intervallo di 1 secondo (1000 millisecondi)
    } else {
      // Il tempo è già scaduto
      this.resetButtonDisabled = false;
      this.resetRequestTime = null;
      this.remainingTime = null;
      this.minutes = null;
      this.seconds = null;
      localStorage.removeItem('resetButtonData');
    }
  }

  ngOnDestroy() {
    clearInterval(this.intervalId);
  }

  checkVerificationCode(): void {
    const {first, second, third, fourth, fifth, sixth} = this.inputValues;
    const concatenatedValues = `${first}${second}${third}${fourth}${fifth}${sixth}`;

    this.authService.verifyOtp(concatenatedValues, this.form.value.email).pipe(
      catchError(error => {
        this.isLoading = false;
        this.errore = error.error.message;
        return throwError(error);
      })
    ).subscribe(response => {
      this.typeForm = 'changePassword'
    });
  }

  changePasswordSubmit(): void {
    const {email} = this.form.value;
    const {password, confermaPassword} = this.changePassword.value;

    this.authService.changePassword(email, password, confermaPassword).pipe(
      catchError(error => {
        this.isLoading = false;
        this.errore = error.error.message;
        return throwError(error);
      })
    ).subscribe(response => {
      this.success = 'Password modificata con successo';
      this.errore = '';
      this.typeForm = 'login'
    });
  }

  changeTypeForm(type: string): void {
    this.errore = '';
    if (type === 'login') {
      this.typeForm = 'login';
      this.clearValidators(['nome', 'cognome', 'telefono', 'confermaPassword', 'acceptPolicy']);
    } else if (type === 'register') {
      this.typeForm = 'register';
      this.setValidators(['nome', 'cognome', 'telefono', 'confermaPassword']);
    } else if (type === 'reset') {
      this.typeForm = 'reset';
      this.clearValidators(['password']);
      this.setValidators(['email']);
    }
  }

  handleKeyUp(input: HTMLInputElement, position: number) {
    const value = input.value;
    const maxLength = input.maxLength;

    if (value.length === maxLength) {
      // Move focus to the next input field
      const nextInput = input.nextElementSibling as HTMLInputElement;
      if (nextInput) {
        nextInput.focus();
      }
    } else if (value.length === 0 && position > 1) {
      // Move focus to the previous input field
      const previousInput = input.previousElementSibling as HTMLInputElement;
      if (previousInput) {
        previousInput.focus();
      }
    }
  }

  private setValidators(fields: string[]): void {
    fields.forEach((field) => {
      this.form.get(field)?.setValidators([Validators.required]);
      this.form.get(field)?.updateValueAndValidity();
    });
  }

  private clearValidators(fields: string[]): void {
    fields.forEach((field) => {
      this.form.get(field)?.clearValidators();
      this.form.get(field)?.updateValueAndValidity();
    });
  }




  closeModal() {
    this.authService.loginModalEvent$.next({stato: false, form: 'login'});
  }

  doubleOptin() {
    const {first, second, third, fourth, fifth, sixth} = this.inputValues;
    const concatenatedValues = `${first}${second}${third}${fourth}${fifth}${sixth}`;

    this.authService.doubleOptin(concatenatedValues, this.form.value.email).pipe(
      catchError(error => {
        this.isLoading = false;
        this.errore = error.error.message;
        return throwError(error);
      })
    ).subscribe(response => {
      this.typeForm = 'login'
      this.errore = '';
      this.success = 'Registrazione completata con successo, Accedi per continuare'
    });
  }

}
