import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { AuthService } from "../auth/auth.service";

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  protected userTokenDetail: any = {};

  constructor(
    private router: Router,
    private authService: AuthService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (isPlatformBrowser(this.platformId)) {
      const adminUser = sessionStorage.getItem('tokenAdmin');
      if (adminUser) {
        this.userTokenDetail = adminUser;
      } else {
        this.userTokenDetail = localStorage.getItem('token');
      }
    }

    if (this.userTokenDetail) {
      req = req.clone({
        setHeaders: {
          Authorization: 'Bearer ' + this.userTokenDetail
        }
      });
    }

    return next.handle(req).pipe(
      map((event: any) => {
        if (event instanceof HttpResponse) {
          const response = event.body;
          if (
            response.message &&
            response.message !== '' &&
            req.method !== 'GET' &&
            response.message !== 'Redirect to this url.'
          ) {
            this.showSuccess(response.message);
          }
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 200 || error.status === 201) {
          return throwError(error);
        }
        switch (error.status) {
          case 400:
            this.handleBadRequest(error);
            break;
          case 401:
          case 403:
            this.handleUnAuthorized();
            break;
          case 500:
            this.handleServerError();
            break;
          default:
            break;
        }
        return throwError(error);
      })
    );
  }

  private handleBadRequest(error: HttpErrorResponse): void {
    if (error.error) {
      try {
        const errorMessage = error.error.message || 'Bad Request';
        this.handleErrorMessages(errorMessage);
      } catch (err) {
        console.error('Error parsing bad request error', err);
      }
    }
  }

  private handleErrorMessages(message: string): void {
    if (message) {
      this.showNotificationError(message);
    }
  }

  private handleUnAuthorized() {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.clear();
      // this.authService.loginModalEvent$.next({ stato: true, form: 'login', text: 'Accedi ora' });
      this.router.navigate(['/login']); // Optional: Redirect to login page
    }
  }

  private handleServerError() {
    this.showNotificationError('Server Error');
  }

  private showNotificationError(message: string): void {
    /* this.snackBar.open(message, '×', {
      panelClass: 'error',
      verticalPosition: 'top',
      horizontalPosition: 'right',
      duration: 3000
    }); */
  }

  private showSuccess(message: string) {
    /* this.snackBar.open(message, '×', {
      panelClass: 'success',
      verticalPosition: 'top',
      horizontalPosition: 'right',
      duration: 3000
    }); */
  }
}
