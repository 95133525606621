import { createAction, props } from '@ngrx/store';
export const addToCart = createAction(
  '[Cart] Add To Cart',
  props<{ item: any }>()
);

export const addToCartSuccess = createAction(
  '[Cart] Add To Cart Success',
  props<{ item: any }>()
);

export const addToCartFailure = createAction(
  '[Cart] Add To Cart Failure',
  props<{ error: any }>()
);

export const clearCart = createAction(
  '[Cart] Clear Cart'
);


export const removeFromCart = createAction(
  '[Cart] Remove Item',
  props<{ itemId: number }>()
);

export const removeFromCartSuccess = createAction(
  '[Cart] Remove Item Success',
  props<{ itemId: number }>()
);

export const removeFromCartFailure = createAction(
  '[Cart] Remove Item Failure',
  props<{ error: any }>()
);

export const loadCartItems = createAction(
  '[Cart] Load Items'
);

export const loadCartItemsSuccess = createAction(
  '[Cart] Load Items Success',
  props<{ items: any[] }>()
);

export const loadCartItemsFailure = createAction(
  '[Cart] Load Items Failure',
  props<{ error: any }>()
);
