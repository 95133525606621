import { CommonModule } from '@angular/common';
import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { catchError, throwError } from 'rxjs';
import { RecensioniService } from 'src/core/recensioni/recensioni.service';
import { environment } from 'src/environments/environment';
import Swiper from 'swiper';
import {Autoplay} from 'swiper/modules';
import {SkeletonModule} from "primeng/skeleton";

@Component({
  standalone: true,
  selector: 'app-recensioni',
  templateUrl: './recensioni.component.html',
  styleUrls: ['./recensioni.component.scss'],
  imports: [
    CommonModule,
    SkeletonModule
  ],
  providers: [{
    provide: Loader,
    useValue: new Loader({
      apiKey: environment.google.key,
      libraries: ['places']
    })
  }]
})
export class RecensioniComponent implements OnInit, AfterViewChecked {
  service: any;
  recensioni: any[] = [];
  request = {
    placeId: "ChIJl2QsbW__OhMR6WiGGzCApLw",
    fields: ['reviews']
  };
  swiper: Swiper | null = null;

  constructor(
    private recensioniService: RecensioniService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.getReviews();
  }

  ngAfterViewChecked(): void {
    if (this.recensioni.length > 0 && !this.swiper) {
      this.initSwiper();
    }
  }

  getSlidesPerView(): number {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1000) {
      return 3; // Visualizza 3 slide su schermi larghi
    } else if (screenWidth >= 768) {
      return 2; // Visualizza 2 slide su schermi medi
    } else {
      return 1; // Visualizza 1 slide su schermi stretti (come smartphone)
    }
  }

  initSwiper() {
    console.log('Initializing Swiper');
    this.swiper = new Swiper('.swiper-cont', {
      modules: [Autoplay],
      direction: 'horizontal', // Orizzontale
      loop: true, // Per creare un loop infinito
      autoplay: {
        //delay: 1500,
        disableOnInteraction: false, // Mantieni l'autoplay attivo anche dopo l'interazione
      },
      slidesPerView: this.getSlidesPerView(),
      spaceBetween: 50,
    });

    console.log('Swiper initialized:', this.swiper);

    window.addEventListener('resize', () => {
      if (this.swiper) {
        this.swiper.params.slidesPerView = this.getSlidesPerView();
        this.swiper.update();
      }
    });
  }

  getReviews() {
    this.recensioniService.getRecensioni()
      .pipe(
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      )
      .subscribe((response: any) => {
        this.recensioni = response.data;
        console.log('Recensioni caricate:', this.recensioni);
        this.changeDetectorRef.detectChanges();
      });
  }


}
