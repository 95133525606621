
<!-- <footer>
  <app-recensioni style="z-index: 0;"></app-recensioni>
  <br>
  <div class="flex bg-[#333333] py-5 items-center justify-center">

    <h4 class="aviano-regular sm:text-center sm:text-2xl text-white mr-3 text-left max-sm:ml-5 text-lg" i18n>Iscriviti
      alla newsletter</h4>

    <div class="flex justify-center items-center max-sm:mr-4 ">
      <button class="button-slider items-center" (click)="openModal = true">
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9.98177 3.79984C11.6186 5.2033 13.2472 6.59644 14.884 7.9999L10.946 0.187988L2.60815 7.9999C5.06333 6.59644 7.5266 5.19298 9.98177 3.79984Z"
            fill="white" />
          <path
            d="M7.75147 11.0959C9.3823 12.2988 11.0213 13.493 12.6521 14.6959L8.71699 8L0.376221 14.6871C2.83464 13.493 5.29306 12.29 7.75147 11.0959Z"
            fill="white" />
        </svg>
      </button>
    </div>

  </div>
  
  <div
    class="w-full h-full flex flex-col justify-center flex-wrap px-8 py-8 bg-[#181818] lg:px-16 lg:flex-row lg:justify-between lg:flex-nowrap">
    <ng-container *ngFor="let column of footerData.columns">
      <div class="flex flex-col text-white">
        <div class="flex flex-row items-center">
          <h1 class="rajdhani-bold text-4xl text-[#e31818] mr-4 cursor-pointer lg:cursor-default lg:text-xl"
          [ngClass]="{'highlighted-title': column.title === highlightedTitle}"
            (click)="toggleDropdown(column)">
            {{ column.title }}
          </h1>
          <span (click)="toggleDropdown(column)" class="cursor-pointer lg:hidden"
            [style.transform]="isDropdownOpen(column) ? 'rotate(180deg)' : ''">
            <svg width="32" height="22" viewBox="0 0 64 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_1_426)">
                <path id="tri1"
                  d="M32.0104 15.6122C21.3264 10.4082 10.6632 5.20408 0 0L32.0104 22.6531L64 0C53.3368 5.20408 42.6736 10.4082 32.0104 15.6122Z"
                  fill="#3a3736" />
              </g>
              <defs>
                <clipPath id="clip0_1_426">
                  <rect width="64" height="44" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </span>
        </div>
        <div class="w-full h-3"></div>
        <div [ngClass]="{
          'max-h-0 overflow-hidden': !isDropdownOpen(column) && !isLgScreen,
          'max-h-screen overflow-visible': isDropdownOpen(column) && !isLgScreen,
          'lg:block': true,
          'transition-all duration-700 ease-in-out': true
        }">
           <br *ngIf="isDropdownOpen(column)"> 
          <ng-container *ngFor="let link of column.links">
            <a [href]="link.href" class="mb-2">
              <div class="flex flex-row justify-start items-center mb-2">
                <div class="w-1/6">
                  <svg width="26" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M21.365 10.0829C18.2762 13.271 15.1745 16.4591 12.0857 19.6472L25.9462 9.99365L20.145 0.340088C20.5603 3.59195 20.9627 6.83106 21.365 10.0829Z"
                      fill="white" />
                    <path
                      d="M10.1779 10.0829C7.08916 13.271 4.00039 16.4591 0.911621 19.6472L14.7592 9.99365L8.94502 0.340088C9.37329 3.59195 9.77561 6.83106 10.1779 10.0829Z"
                      fill="white" />
                  </svg>
                </div>
                <p class="aviano-regular text-xl lg:text-sm w-5/6">{{ link.text }}</p>
              </div>
            </a>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>


  <div class=" bg-[#181818]  border-y border-[#333333] py-2 ">
    <p class="text-center p-5 rajdhani-regular text-xl text-[#5b5b5b]">{{ 'FOOTER.dirittiRiservati' | translate }} 
      <a href="/condizioni-generali-di-vendita" class="text-center p-5 rajdhani-regular text-xl text-[#5b5b5b]">Condizioni Generali Di Vendita</a></p>


  </div>
</footer>

<div *ngIf="openModal" [formGroup]="form"
  class="absolute inset-0 flex items-center justify-center z-50 overflow-hidden">
  <div
    class="fixed inset-0 backdrop-blur bg-gray-500 bg-opacity-50 flex items-center z-[51] justify-center overflow-hidden">
  </div>
  <div
    class="bg-white fixed z-[99] w-500 w-[100%] max-md:h-full  md:w-[60%]  lg:w-[40%] xl:w-[30%] h-500 flex overflow-hidden items-center justify-center  shadow-lg">
    <div class="text-center w-full mx-4 md:mx-9">

      <div class="flex justify-center items-center">
        <img class="w-24 mt-4 my-4 mx-auto" width=141 height=50 src="../../../../assets/icon/logo_color.svg"
          alt="logo we can race srl" />

        <button class="bg-transparent" (click)="closeModal()">
          <i class="pi pi-times"></i>
        </button>
      </div>


      <div class="container">

        <div *ngIf="send">
          <h5 class="text-xl rajdhani-medium uppercase mb-4 text-center py-2" i18n>{{form.get('email').nome}} benvenuto
            nel team</h5>
          <h5 class="text-xl rajdhani-medium uppercase mb-4 text-center py-2" i18n>Ti ho inviato una mail a
            {{form.get('email').value}} controlla anche in spam</h5>
        </div>

        <ng-container *ngIf="!send">
          <h2 class="text-3xl rajdhani-medium text-center py-2">{{ 'FOOTER.moduloIscrizioneTitolo' | translate }}</h2>

          <div class="my-3">
            <input type="text" pInputText name="nome" id="nome" placeholder="{{ 'FOOTER.nomePlaceholder' | translate }}"
              formControlName="nome"
              class="bg-[#F2F2F2] block w-full border-none rounded-sm outline-0 py-3 text-gray-900 shadow-sm  placeholder:text-gray-400 sm:text-sm sm:leading-6">
          </div>

          <div class="my-3">
            <input type="text" pInputText name="nome" placeholder="{{ 'FOOTER.emailPlaceholder' | translate }}"
              formControlName="email"
              class="bg-[#F2F2F2] block w-full border-none rounded-sm outline-0 py-3 text-gray-900 shadow-sm  placeholder:text-gray-400 sm:text-sm sm:leading-6">
          </div>

          <div class="my-3">
            <p-autoComplete [(ngModel)]="selectedProvincia" [ngModelOptions]="{standalone: true}"
              [suggestions]="filteredProvincia" placeholder="{{ 'FOOTER.provinciaPlaceholder' | translate }}"
              [dropdown]="true" [style]="{ width: '100%'}" class="account"
              (onSelect)="this.form.patchValue({provincia: $event});" (completeMethod)="filterProvincia($event)"
              field="nome">
            </p-autoComplete>
          </div>

          <div class="my-3">
            <p-checkbox value="1" [binary]="true" formControlName="privacy"
              (onChange)="this.form.patchValue({privacy: $event.checked});"></p-checkbox>
            {{ 'FOOTER.accettoPrivacy' | translate }} <a routerLink="/privacy-policy" class="text-red-500">privacy</a> .
          </div>

          <div class="my-3">
            <button type="submit" [disabled]="form.invalid" (click)="sendNewsLetter()"
              class="mt-4 w-full cursor-pointer aviano-regular text-xl text-white bg-[#CC0000] disabled:bg-neutral-600 py-3">
              {{ 'FOOTER.iscriviti' | translate }}
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

-->


<app-recensioni style="z-index: 0;"></app-recensioni>
<div class="flex bg-[#333333] py-5 items-center justify-center">

  <h4 class="aviano-regular sm:text-center sm:text-2xl text-white mr-3 text-left max-sm:ml-5 text-lg" i18n>Iscriviti
    alla newsletter</h4>

  <div class="flex justify-center items-center max-sm:mr-4 ">
    <button class="button-slider items-center" (click)="openModal = true">
      <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.98177 3.79984C11.6186 5.2033 13.2472 6.59644 14.884 7.9999L10.946 0.187988L2.60815 7.9999C5.06333 6.59644 7.5266 5.19298 9.98177 3.79984Z"
          fill="white" />
        <path
          d="M7.75147 11.0959C9.3823 12.2988 11.0213 13.493 12.6521 14.6959L8.71699 8L0.376221 14.6871C2.83464 13.493 5.29306 12.29 7.75147 11.0959Z"
          fill="white" />
      </svg>
    </button>
  </div>

</div>
<footer *ngIf="windowWidth > 1130">
  <div class="flexDima">
    <div class="col" >
      <h2>MENU</h2>
      <div class="dc" *ngFor="let i of col1" (click)="navigateTo(i.key)">
        <svg  viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M21.365 10.0829C18.2762 13.271 15.1745 16.4591 12.0857 19.6472L25.9462 9.99365L20.145 0.340088C20.5603 3.59195 20.9627 6.83106 21.365 10.0829Z"
            fill="white" />
          <path
            d="M10.1779 10.0829C7.08916 13.271 4.00039 16.4591 0.911621 19.6472L14.7592 9.99365L8.94502 0.340088C9.37329 3.59195 9.77561 6.83106 10.1779 10.0829Z"
            fill="white" />
        </svg>
        <h4 >
          {{ i.value }}
        </h4>
      </div>
    </div>

    <div class="col" >
      <h2>ESPERIENZE</h2>
      <div class="dc" *ngFor="let i of col2" (click)="navigateHref(i.key)">
        <svg  viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M21.365 10.0829C18.2762 13.271 15.1745 16.4591 12.0857 19.6472L25.9462 9.99365L20.145 0.340088C20.5603 3.59195 20.9627 6.83106 21.365 10.0829Z"
            fill="white" />
          <path
            d="M10.1779 10.0829C7.08916 13.271 4.00039 16.4591 0.911621 19.6472L14.7592 9.99365L8.94502 0.340088C9.37329 3.59195 9.77561 6.83106 10.1779 10.0829Z"
            fill="white" />
        </svg>
        <h4 >
          {{ i.value }}
        </h4>
      </div>
    </div>

    <div class="col" >
      <h2>NOLEGGIO</h2>
      <div class="dc" *ngFor="let i of col3" (click)="navigateTo(i.key)">
        <svg  viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M21.365 10.0829C18.2762 13.271 15.1745 16.4591 12.0857 19.6472L25.9462 9.99365L20.145 0.340088C20.5603 3.59195 20.9627 6.83106 21.365 10.0829Z"
            fill="white" />
          <path
            d="M10.1779 10.0829C7.08916 13.271 4.00039 16.4591 0.911621 19.6472L14.7592 9.99365L8.94502 0.340088C9.37329 3.59195 9.77561 6.83106 10.1779 10.0829Z"
            fill="white" />
        </svg>
        <h4 >
          {{ i.value }}
        </h4>
      </div>
    </div>

    <div class="col" >
      <h2 id="yellow">PRENOTAZIONI</h2>
      <div class="dc" *ngFor="let i of col4" (click)="navigateTo(i.key)">
        <svg  viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M21.365 10.0829C18.2762 13.271 15.1745 16.4591 12.0857 19.6472L25.9462 9.99365L20.145 0.340088C20.5603 3.59195 20.9627 6.83106 21.365 10.0829Z"
            fill="white" />
          <path
            d="M10.1779 10.0829C7.08916 13.271 4.00039 16.4591 0.911621 19.6472L14.7592 9.99365L8.94502 0.340088C9.37329 3.59195 9.77561 6.83106 10.1779 10.0829Z"
            fill="white" />
        </svg>
        <h4 >
          {{ i.value }}
        </h4>
      </div>
    </div>

    <div class="col" >
      <h2>... SU DI NOI</h2>
      <div class="dc" *ngFor="let i of col5" (click)="navigateTo(i.key)">
        <svg  viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M21.365 10.0829C18.2762 13.271 15.1745 16.4591 12.0857 19.6472L25.9462 9.99365L20.145 0.340088C20.5603 3.59195 20.9627 6.83106 21.365 10.0829Z"
            fill="white" />
          <path
            d="M10.1779 10.0829C7.08916 13.271 4.00039 16.4591 0.911621 19.6472L14.7592 9.99365L8.94502 0.340088C9.37329 3.59195 9.77561 6.83106 10.1779 10.0829Z"
            fill="white" />
        </svg>
        <h4 >
          {{ i.value }}
        </h4>
      </div>
    </div>

    <div class="col" >
      <h2>CONTATTI</h2>
      <div class="dc" *ngFor="let i of col6" (click)="navigateHref(i.key)">
        <svg  viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M21.365 10.0829C18.2762 13.271 15.1745 16.4591 12.0857 19.6472L25.9462 9.99365L20.145 0.340088C20.5603 3.59195 20.9627 6.83106 21.365 10.0829Z"
            fill="white" />
          <path
            d="M10.1779 10.0829C7.08916 13.271 4.00039 16.4591 0.911621 19.6472L14.7592 9.99365L8.94502 0.340088C9.37329 3.59195 9.77561 6.83106 10.1779 10.0829Z"
            fill="white" />
        </svg>
        <h4 >
          {{ i.value }}
        </h4>
      </div>
    </div>










  </div>
</footer>

<footer *ngIf="windowWidth < 1130">
  <img src="../../../../assets/images/logo.png" alt="">
<div class="info">
  <div class="colMobile">
    <svg [ngStyle]="{'align-self': flagCol === 0 ? 'flex-start' : 'initial'}" width="26" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.365 10.0829C18.2762 13.271 15.1745 16.4591 12.0857 19.6472L25.9462 9.99365L20.145 0.340088C20.5603 3.59195 20.9627 6.83106 21.365 10.0829Z" fill="white" />
      <path d="M10.1779 10.0829C7.08916 13.271 4.00039 16.4591 0.911621 19.6472L14.7592 9.99365L8.94502 0.340088C9.37329 3.59195 9.77561 6.83106 10.1779 10.0829Z" fill="white" />
    </svg>
    <div class="flex-col">
      <h2  (click)="onClickFlag(0)">MENU</h2>
      <div class="flag-content" [class.show]="flagCol === 0">
          <h4  *ngFor="let x of col1" (click)="navigateTo(x.key)">{{ x.value }}</h4>
      </div>
    </div>
  </div>
  
  <div class="colMobile">
    <svg [ngStyle]="{'align-self': flagCol === 1 ? 'flex-start' : 'initial'}" width="26" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.365 10.0829C18.2762 13.271 15.1745 16.4591 12.0857 19.6472L25.9462 9.99365L20.145 0.340088C20.5603 3.59195 20.9627 6.83106 21.365 10.0829Z" fill="white" />
      <path d="M10.1779 10.0829C7.08916 13.271 4.00039 16.4591 0.911621 19.6472L14.7592 9.99365L8.94502 0.340088C9.37329 3.59195 9.77561 6.83106 10.1779 10.0829Z" fill="white" />
    </svg>
    <div class="flex-col">
      <h2 (click)="onClickFlag(1)">ESPERIENZA</h2>
      <div class="flag-content" [class.show]="flagCol === 1">
          <h4 *ngFor="let x of col2" (click)="navigateHref(x.key)">{{ x.value }}</h4>
      </div>
    </div>
  </div>

  <div class="colMobile">
    <svg [ngStyle]="{'align-self': flagCol === 2 ? 'flex-start' : 'initial'}" width="26" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.365 10.0829C18.2762 13.271 15.1745 16.4591 12.0857 19.6472L25.9462 9.99365L20.145 0.340088C20.5603 3.59195 20.9627 6.83106 21.365 10.0829Z" fill="white" />
      <path d="M10.1779 10.0829C7.08916 13.271 4.00039 16.4591 0.911621 19.6472L14.7592 9.99365L8.94502 0.340088C9.37329 3.59195 9.77561 6.83106 10.1779 10.0829Z" fill="white" />
    </svg>
    <div class="flex-col">
      <h2 (click)="onClickFlag(2)">NOLEGGIO</h2>
      <div class="flag-content" [class.show]="flagCol === 2">
          <h4 *ngFor="let x of col3" (click)="navigateTo(x.key)">{{ x.value }}</h4>
      </div>
    </div>
  </div>

  


  <div class="colMobile">
    <svg [ngStyle]="{'align-self': flagCol === 3 ? 'flex-start' : 'initial'}" width="26" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.365 10.0829C18.2762 13.271 15.1745 16.4591 12.0857 19.6472L25.9462 9.99365L20.145 0.340088C20.5603 3.59195 20.9627 6.83106 21.365 10.0829Z" fill="white" />
      <path d="M10.1779 10.0829C7.08916 13.271 4.00039 16.4591 0.911621 19.6472L14.7592 9.99365L8.94502 0.340088C9.37329 3.59195 9.77561 6.83106 10.1779 10.0829Z" fill="white" />
    </svg>
    <div class="flex-col">
      <h2 id="yellow" (click)="onClickFlag(3)">PRENOTAZIONI</h2>
      <div class="flag-content" [class.show]="flagCol === 3">
          <h4 *ngFor="let x of col4" (click)="navigateTo(x.key)">{{ x.value }}</h4>
      </div>
    </div>
  </div>

  <div class="colMobile">
    <svg [ngStyle]="{'align-self': flagCol === 4 ? 'flex-start' : 'initial'}" width="26" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.365 10.0829C18.2762 13.271 15.1745 16.4591 12.0857 19.6472L25.9462 9.99365L20.145 0.340088C20.5603 3.59195 20.9627 6.83106 21.365 10.0829Z" fill="white" />
      <path d="M10.1779 10.0829C7.08916 13.271 4.00039 16.4591 0.911621 19.6472L14.7592 9.99365L8.94502 0.340088C9.37329 3.59195 9.77561 6.83106 10.1779 10.0829Z" fill="white" />
    </svg>
    <div class="flex-col">
      <h2 (click)="onClickFlag(4)">SU DI NOI</h2>
      <div class="flag-content" [class.show]="flagCol === 4">
          <h4 *ngFor="let x of col5" (click)="navigateTo(x.key)">{{ x.value }}</h4>
      </div>
    </div>
  </div>


  <div class="colMobile">
    <svg [ngStyle]="{'align-self': flagCol === 5 ? 'flex-start' : 'initial'}" width="26" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.365 10.0829C18.2762 13.271 15.1745 16.4591 12.0857 19.6472L25.9462 9.99365L20.145 0.340088C20.5603 3.59195 20.9627 6.83106 21.365 10.0829Z" fill="white" />
      <path d="M10.1779 10.0829C7.08916 13.271 4.00039 16.4591 0.911621 19.6472L14.7592 9.99365L8.94502 0.340088C9.37329 3.59195 9.77561 6.83106 10.1779 10.0829Z" fill="white" />
    </svg>
    <div class="flex-col">
      <h2  (click)="onClickFlag(5)" >CONTATTI</h2>
      <div class="flag-content" [class.show]="flagCol === 5">
          <h4 id="fp" *ngFor="let x of col6" (click)="navigateHref(x.key)">{{ x.value }}</h4>
          
      </div>
    </div>
  </div>







</div>
</footer>
<div class=" bg-[#181818]  border-y border-[#333333] py-2 ">
  <p class="text-center p-5 rajdhani-regular text-xl text-[#5b5b5b]">{{ 'FOOTER.dirittiRiservati' | translate }} 
    <a href="/condizioni-generali-di-vendita" class="text-center p-5 rajdhani-regular text-xl text-[#5b5b5b]">Condizioni Generali Di Vendita</a></p>
</div>

<div *ngIf="openModal" [formGroup]="form"
  class="absolute inset-0 flex items-center justify-center z-50 overflow-hidden">
  <div
    class="fixed inset-0 backdrop-blur bg-gray-500 bg-opacity-50 flex items-center z-[51] justify-center overflow-hidden">
  </div>
  <div
    class="bg-white fixed z-[99] w-500 w-[100%] max-md:h-full  md:w-[60%]  lg:w-[40%] xl:w-[30%] h-500 flex overflow-hidden items-center justify-center  shadow-lg">
    <div class="text-center w-full mx-4 md:mx-9">

      <div class="flex justify-center items-center">
        <img class="w-24 mt-4 my-4 mx-auto" width=141 height=50 src="../../../../assets/icon/logo_color.svg"
          alt="logo we can race srl" />

        <button class="bg-transparent" (click)="closeModal()">
          <i class="pi pi-times"></i>
        </button>
      </div>


      <div class="container">

        <div *ngIf="send">
          <h5 class="text-xl rajdhani-medium uppercase mb-4 text-center py-2" i18n>{{form.get('email').nome}} benvenuto
            nel team</h5>
          <h5 class="text-xl rajdhani-medium uppercase mb-4 text-center py-2" i18n>Ti ho inviato una mail a
            {{form.get('email').value}} controlla anche in spam</h5>
        </div>

        <ng-container *ngIf="!send">
          <h2 class="text-3xl rajdhani-medium text-center py-2">{{ 'FOOTER.moduloIscrizioneTitolo' | translate }}</h2>

          <div class="my-3">
            <input type="text" pInputText name="nome" id="nome" placeholder="{{ 'FOOTER.nomePlaceholder' | translate }}"
              formControlName="nome"
              class="bg-[#F2F2F2] block w-full border-none rounded-sm outline-0 py-3 text-gray-900 shadow-sm  placeholder:text-gray-400 sm:text-sm sm:leading-6">
          </div>

          <div class="my-3">
            <input type="text" pInputText name="nome" placeholder="{{ 'FOOTER.emailPlaceholder' | translate }}"
              formControlName="email"
              class="bg-[#F2F2F2] block w-full border-none rounded-sm outline-0 py-3 text-gray-900 shadow-sm  placeholder:text-gray-400 sm:text-sm sm:leading-6">
          </div>

          <div class="my-3">
            <p-autoComplete [(ngModel)]="selectedProvincia" [ngModelOptions]="{standalone: true}"
              [suggestions]="filteredProvincia" placeholder="{{ 'FOOTER.provinciaPlaceholder' | translate }}"
              [dropdown]="true" [style]="{ width: '100%'}" class="account"
              (onSelect)="this.form.patchValue({provincia: $event});" (completeMethod)="filterProvincia($event)"
              field="nome">
            </p-autoComplete>
          </div>

          <div class="my-3">
            <p-checkbox value="1" [binary]="true" formControlName="privacy"
              (onChange)="this.form.patchValue({privacy: $event.checked});"></p-checkbox>
            {{ 'FOOTER.accettoPrivacy' | translate }} <a routerLink="/privacy-policy" class="text-red-500">privacy</a> .
          </div>

          <div class="my-3">
            <button type="submit" [disabled]="form.invalid" (click)="sendNewsLetter()"
              class="mt-4 w-full cursor-pointer aviano-regular text-xl text-white bg-[#CC0000] disabled:bg-neutral-600 py-3">
              {{ 'FOOTER.iscriviti' | translate }}
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<!-- <svg width="26" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M21.365 10.0829C18.2762 13.271 15.1745 16.4591 12.0857 19.6472L25.9462 9.99365L20.145 0.340088C20.5603 3.59195 20.9627 6.83106 21.365 10.0829Z"
    fill="white" />
  <path
    d="M10.1779 10.0829C7.08916 13.271 4.00039 16.4591 0.911621 19.6472L14.7592 9.99365L8.94502 0.340088C9.37329 3.59195 9.77561 6.83106 10.1779 10.0829Z"
    fill="white" />
</svg>
-->