import { BlankPageModule } from './blank-page/blank-page.module';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from "./layout/layout.component";
import { AuthGuard } from "../core/auth/auth.guard";
import { NotFoundComponent } from "./not-found/not-found.component";
import { ManutenzioneComponent } from "./manutenzione/manutenzione.component";
import { MaintenanceGuard } from "../core/auth/maintenance.guard";
import { ProMoresComponent } from './pro-mores/pro-mores.component';


const routes: Routes = [
  {
    path: 'manutenzione',
    component: ManutenzioneComponent,
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [MaintenanceGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./home/home.module').then((m) => m.HomeModule),
      },
      {
        canActivate: [AuthGuard],
        path: 'checkout',
        loadChildren: () =>
          import('./checkout/checkout.module').then((m) => m.CheckoutModule),
      },
      {
        canActivate: [AuthGuard],
        path: 'account',
        loadChildren: () =>
          import('./account/account.module').then((m) => m.AccountModule),
      },
      {
        path: 'regala-esperienza-in-ferrari-e-lamborghini',
        loadChildren: () =>
          import('./cofanetti/cofanetti.module').then((m) => m.CofanettiModule),
      },
      {
        path: 'gift-card',
        loadChildren: () =>
          import('./gift-card/gift-card.module').then((m) => m.GiftCardModule),
      },
      {
        path: 'cofanetto/:id',
        loadChildren: () =>
          import('./cofanetto/cofanetto.module').then((m) => m.CofanettoModule),
      },
      {
        path: 'cookie-policy',
        loadChildren: () =>
          import('./cookie-policy/cookie-policy.module').then(
            (m) => m.CookiePolicyModule
          ),
      },
      {
        path: 'privacy-policy',
        loadChildren: () =>
          import('./privacy-policy/privacy-policy.module').then(
            (m) => m.PrivacyPolicyModule
          ),
      },
      {
        path: 'consenso-cookie',
        loadChildren: () =>
          import('./consenso-cookie/consenso-cookie.module').then(
            (m) => m.ConsensoCookieModule
          ),
      },
      {
        path: 'attivazione-cofanetto',
        loadChildren: () =>
          import('./prenotazione/prenotazione.module').then(
            (m) => m.PrenotazioneModule
          ),
      },
      {
        path: 'prenotazione',
        loadChildren: () =>
          import('./prenotazione/prenotazione.module').then(
            (m) => m.PrenotazioneModule
          ),
      },
      {
        path: 'perche-noi',
        loadChildren: () =>
          import('./perche-noi/perche-noi.module').then(
            (m) => m.PercheNoiModule
          ),
      },
      {
        path: 'chi-siamo',
        loadChildren: () =>
          import('./chi-siamo/chi-siamo.module').then((m) => m.ChiSiamoModule),
      },
      {
        path: 'active-account',
        loadChildren: () =>
          import('./active-account/active-account.module').then(
            (m) => m.ActiveAccountModule
          ),
      },
      {
        path: 'eventi-aziendali',
        loadChildren: () =>
          import('./eventi-aziendali/eventi-aziendali.module').then(
            (m) => m.EventiAziendaliModule
          ),
      },
      {
        path: 'calendario',
        loadChildren: () =>
          import('./calendario/calendario.module').then(
            (m) => m.CalendarioModule
          ),
      },
      {
        path: 'reset-password',
        loadChildren: () => import('./reset-password/reset-password.module').then((m) => m.ResetPasswordModule),
      },
      {
        canActivate: [AuthGuard],
        path: 'paga-ordine/:token',
        loadChildren: () => import('./paga-ordine/paga-ordine.module').then(m => m.PagaOrdineModule)
      },
      {
        path: 'circuiti',
        loadChildren: () => import('./circuiti/circuiti.module').then(m => m.CircuitiModule)
      },
      {
        path: 'cessione-esperienza/:token',
        loadChildren: () => import('./cessione-esperienza/cessione-esperienza.module').then(m => m.CessioneEsperienzaModule)
      },
      {
        path: 'blog',
        loadChildren: () =>
          import('./blog/blog.module').then((m) => m.BlogModule),
      },
      {
        path: 'noleggio',
        loadChildren: () =>
          import('./noleggio/noleggio.module').then((m) => m.NoleggioModule),
      },
      {
        path: 'noleggio-per-foto-shooting',
        loadChildren: () =>
          import('./noleggio-per-foto-shooting/noleggio-per-foto-shooting.module').then((m) => m.NoleggioPerFotoShootingModule),
      },
      {
        path: 'noleggio-ferrari-lamborghini-per-matrimonio-cerimonia',
        loadChildren: () =>
          import('./noleggio-ferrari-lamborghini-per-matrimonio-cerimonia/noleggio-ferrari-lamborghini-per-matrimonio-cerimonia.module').then((m) => m.NoleggioFerrariLamborghiniPerMatrimonioCerimoniaModule),
      },
      {
        path: 'noleggio-simulatore',
        loadChildren: () =>
          import('./noleggio-simulatore/noleggio-simulatore.module').then((m) => m.NoleggioSimulatoreModule),
      },
      {
        path: 'diciottesimi',
        loadChildren: () =>
          import('./diciottesimi/diciottesimi.module').then((m) => m.DiciottesimiModule),
      },
      {
        path: 'lascia-una-recensione',
        loadChildren: () =>
          import('././lascia-una-recensione/lascia-una-recensione.module').then((m) => m.LasciaUnaRecensioneModule),
      },
      {
        path: 'condizioni-generali-di-vendita',
        loadChildren: () =>
          import('./condizioni-generali-di-vendita/condizioni-generali-di-vendita.module').then((m) => m.CondizioniGeneraliDiVenditaModule),
      },
      {
        path: 'contattaci',
        loadChildren: () =>
          import('./contattaci/contattaci.module').then((m) => m.ContattaciModule),
      },
      {
        path: 'collabora-con-noi',
        loadChildren: () =>
          import('./collabora-con-noi/collabora-con-noi.module').then((m) => m.CollaboraConNoiModule),
      },
      {
        path: 'voucher-regalo',
        loadChildren: () =>
          import('./voucher-regalo/voucher-regalo.module').then((m) => m.VoucherRegaloModule),
      },
      {
        path: 'faq',
        loadChildren: () =>
          import('./faq/faq.module').then((m) => m.FaqModule),
      },
      {
        path: 'grazie-per-la-tua-richiesta',
        loadChildren: () =>
          import('./grazie-per-la-tua-richiesta/grazie-per-la-tua-richiesta.module').then((m) => m.GraziePerLaTuaRichiestaModule),
      },
      {
        path: 'blank-page',
        loadChildren: () =>
          import('./blank-page/blank-page.module').then((m) => m.BlankPageModule),
      },
      {
        path: 'veicoli',
        loadChildren: () =>
          import('./veicoli/veicoli.module').then((m) => m.VeicoliModule),
      },
      {
        path: 'acquista-video',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./acquista-video/acquista-video.module').then((m) => m.AcquistaVideoModule),
      },
      {
        path: 'angelo-test',
        loadChildren: () =>
          import('./pro-mores/pro-mores.module').then((m) => m.ProMoresModule),
      },
      {
        path: 'circuiti/:circuito/:veicolo',
        redirectTo: 'circuiti/:circuito/:veicolo',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: '**',
    component: LayoutComponent,
    canActivate: [MaintenanceGuard],
    children: [
      {
        path: '',
        loadComponent: () => import('./not-found/not-found.component').then((m) => m.NotFoundComponent),
      }
    ],
  },
];






@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled', // Abilita lo scrolling verso gli anchor
      onSameUrlNavigation: 'reload', // Può essere necessario se desideri che l'ancora funzioni anche quando si naviga verso la stessa URL
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }





