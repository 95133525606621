import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {


  private _responseSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');


  constructor() { }

  set response(value: string) {
    this._responseSubject.next(value);
    console.log('Service response:', value);
  }

  get response(): string {
    return this._responseSubject.getValue();
  }

  // Aggiungi un metodo per esporre l'Observable della response
  get responseObservable() {
    return this._responseSubject.asObservable();
  }

}









