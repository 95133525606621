import {Action, createReducer, on} from '@ngrx/store';
import * as CartActions from '../action/cart.action';

export interface CartState {
  items: any[];
  loading: boolean;
  error: any;
  itemCount: number;
}

export const initialState: CartState = {
  items: [],
  loading: false,
  error: null,
  itemCount: 0
};

const _cartReducer = createReducer(
  initialState,
  on(CartActions.addToCart, (state, {item}) => ({
    ...state,
    loading: true,
  })),
  on(CartActions.addToCartSuccess, (state, {item}) => ({
    ...state,
    loading: false,
    items: [...state.items, item],
    itemCount: state.itemCount + 1
  })),
  on(CartActions.addToCartFailure, (state, {error}) => ({
    ...state,
    loading: false,
    error,
  })),
  on(CartActions.removeFromCart, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(CartActions.removeFromCartSuccess, (state, {itemId}) => {
    return {
      ...state,
      loading: false,
      items: state.items.filter(item => item.id !== itemId),
      itemCount: state.itemCount - 1
    };
  }),
  on(CartActions.removeFromCartFailure, (state, {error}) => {
    return {
      ...state,
      loading: false,
      error,
    };
  }),
  on(CartActions.loadCartItems, state => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(CartActions.loadCartItemsSuccess, (state, {items}) => {
    return {
      ...state,
      loading: false,
      items,
      itemCount: items.length
    };
  }),
  on(CartActions.loadCartItemsFailure, (state, {error}) => {
    return {
      ...state,
      loading: false,
      error,
    };
  }),
  on(CartActions.clearCart, (state) => ({
      ...state,
      items: [],
      error: null
    })
  ));


export function cartReducer(state: CartState | undefined, action: Action) {
  return _cartReducer(state, action);
}
