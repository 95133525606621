import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { AuthService } from "./auth.service";
import { environment } from "../../environments/environment";
import { PaymentService } from "../payment/payment.service";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private paymentService: PaymentService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
    return this.checkLogin(state.url);
  }

  checkLogin(url: string): Promise<boolean> | boolean {


    let underMaintenance: boolean;
    let currentUser: any;

    if (isPlatformBrowser(this.platformId)) {
      const adminUser = sessionStorage.getItem('tokenAdmin')

      if (adminUser) {
        currentUser = adminUser;
      } else {
        currentUser = localStorage.getItem('token');
      }
    }


    if (currentUser) {
      if (url === '/') {
        this.router.navigate(['/']);
        return false;
      }
      return true;
    } else {
      this.router.navigate(['/'])
        .then(r => {
          this.authService.loginModalEvent$.next({ stato: true, form: 'register' });
          this.authService.returnUrl$.next(url);
        }).catch(e => {
          console.log(e)
        });
      if (url === '/') {
        return true;
      }
    }
    return false;
  }
}
