<div *ngIf="openModal" [formGroup]="form"
  class="absolute inset-0 flex items-center justify-center z-50 overflow-hidden">
  <div
    class="fixed inset-0 backdrop-blur bg-gray-500 bg-opacity-50 flex items-center z-[51] justify-center overflow-hidden">
  </div>
  <div
    class="bg-white fixed z-[99] w-500 w-[100%] max-md:h-full  md:w-[60%]  lg:w-[40%] xl:w-[30%] h-500 flex overflow-hidden items-center justify-center  shadow-lg">
    <div class="text-center w-full mx-4 md:mx-9">

      <div class="flex justify-center items-center">
        <img class="w-24 mt-4 my-4 mx-auto" width=141 height=50 ngSrc="/assets/images/logo.png"
          alt="logo we can race srl" />

        <button class="bg-transparent" (click)="closeModal()">
          <i class="pi pi-times"></i>
        </button>
      </div>


      <div class="max-w-[90%] mx-auto ">

        <ng-container *ngIf="typeForm == 'login'">
          <p *ngIf="success" class="text-2xl mb-5 flex flex-row text-center items-center justify-center py-2">
            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="11.5" cy="11.5" r="11" stroke="#019110" />
              <path d="M6.27344 11.4993L9.4098 14.6356L15.6825 8.36292" stroke="#019110" />
            </svg>
            <span class="ml-2" i18n>Ciao {{nome}}, ti sei registrato con successo!</span>
          </p>

          <div class="mt-4 mb-4 border-[2px] border-[#f2f2f2]"></div>


          <h2 class="text-3xl rajdhani-medium text-center py-2" i18n>Accedi ora</h2>


          <p *ngIf="errore" class="text-lg text-center font-bold text-red-500 py-2">{{ errore }}</p>

          <div class="relative">
            <input type="text" placeholder="Email" formControlName="email"
              class="mt-4 bg-gray-100 p-4 outline-0 rounded pr-8 w-full">

          </div>
          <div *ngIf="form.get('email').invalid && form.get('email').touched" class="text-red-500" i18n>Il campo Email è
            obbligatorio
          </div>

          <div class="relative">
            <input [type]="fieldTextType ? 'text' : 'password'" placeholder="Password" formControlName="password"
              class="mt-4 bg-gray-100 p-4 outline-0 rounded pr-8 w-full">


            <img class="svgOcchio" (click)="this.fieldTextType = !this.fieldTextType"
              src="../../../../assets/svg/occhio.svg" alt="">
          </div>
          <div *ngIf="form.get('password').invalid && form.get('password').touched" class="text-red-500" i18n>Il campo
            Password è obbligatorio
          </div>

          <div class="mb-3">
            <button type="submit" [disabled]="form.invalid" (click)="handleAuth()"
              class="mt-4 w-full cursor-pointer aviano-regular text-xl text-white bg-[#CC0000] disabled:bg-neutral-600 py-3"
              i18n>
              Accedi
            </button>
          </div>

          <h2 (click)="typeForm = 'reset'; this.errore = ''; this.success = ''"
            class="text-md mb-6 cursor-pointer text-[#9F9F9F]  rajdhani-medium text-right py-2" i18n>
            Password dimenticata?
            <span class="rajdhani-semibold text-[#CC0000]">Recupera</span>
          </h2>

          <h2 (click)="typeForm = 'register'" class="text-xl mb-6 cursor-pointer rajdhani-medium text-center py-2" i18n>
            Non hai un account?
            <span class="rajdhani-semibold text-[#CC0000]">Registrati</span>
          </h2>
        </ng-container>

        <!--  RESET PASSWORD -->

        <ng-container *ngIf="typeForm == 'reset'">
          <h2 class="text-3xl rajdhani-medium text-center py-2" i18n>Accedi</h2>

          <p *ngIf="success" class="text-sm text-center text-gray-500 py-2">{{ success }}</p>
          <p *ngIf="errore" class="text-lg text-center font-bold text-red-500 py-2">{{ errore }}</p>

          <div class="relative">
            <input type="text" placeholder="Email" formControlName="email"
              class="mt-4 bg-gray-100 p-4 outline-0 rounded pr-8 w-full">

          </div>
          <div *ngIf="form.get('email').invalid && form.get('email').touched" class="text-red-500" i18n>Il campo Email è
            obbligatorio
          </div>

          <div class="mb-3">
            <button type="submit" [disabled]="form.get('email').invalid || resetButtonDisabled" (click)="handleAuth()"
              class="mt-4 w-full cursor-pointer aviano-regular text-xl text-white bg-[#CC0000] disabled:bg-neutral-600 py-3">
              <span *ngIf="resetButtonDisabled" i18n>Riprova tra {{ minutes }} : {{ seconds }}</span>
              <span *ngIf="!resetButtonDisabled" i18n>recupera</span>

            </button>
          </div>

          <h2 i18n (click)="typeForm = 'login'" class="text-xl mb-6 cursor-pointer rajdhani-medium text-center py-2">
            Te la sei ricordata?<span class="rajdhani-semibold text-[#CC0000]">Accedi</span>
          </h2>
        </ng-container>

        <!-- REGISTRAZIONE -->

        <ng-container *ngIf="typeForm === 'register'">

          <h2 class="text-3xl rajdhani-medium text-center py-2" i18n>Registrati {{text}}</h2>

          <p *ngIf="errore" class="text-lg text-center font-bold text-red-500 py-2" i18n>{{ errore }}</p>

          <div class="relative">
            <input type="text" placeholder="Nome" i18n-placeholder formControlName="nome"
              class="mt-4 bg-gray-100 p-2 outline-0 rounded pr-8 w-full md:p-4">

          </div>
          <div *ngIf="form.get('nome').invalid && form.get('nome').touched" class="text-red-500" i18n>Il campo Nome è
            obbligatorio
          </div>

          <div class="relative">
            <input type="text" placeholder="Cognome" i18n-placeholder formControlName="cognome"
              class="mt-4 bg-gray-100 p-2 outline-0 rounded pr-8 w-full md:p-4">

          </div>
          <div *ngIf="form.get('cognome').invalid && form.get('cognome').touched" class="text-red-500" i18n>Il campo
            Cognome è obbligatorio
          </div>

          <div class="relative">
            <input type="text" placeholder="Telefono" i18n-placeholder formControlName="telefono"
              class="mt-4 bg-gray-100 p-2 outline-0 rounded pr-8 w-full md:p-4"  minlength="9">



          </div>
          <div *ngIf="form.get('telefono').invalid && form.get('telefono').touched" class="text-red-500" i18n>Il campo
            Telefono è obbligatorio
          </div>

          <div class="relative">
            <input type="text" placeholder="Email" i18n-placeholder formControlName="email"
              class="mt-4 bg-gray-100 p-2 outline-0 rounded pr-8 w-full md:p-4">

          </div>
          <div *ngIf="form.get('email').invalid && form.get('email').touched" i18n class="text-red-500">Il campo Email è
            obbligatorio
          </div>

          <div class="mt-4 border-[2px] border-[#f2f2f2]"></div>

          <div class="relative">
            <input [type]="fieldTextType ? 'text' : 'password'" placeholder="Password" i18n-placeholder
              formControlName="password" class="mt-4 bg-gray-100 p-2 outline-0 rounded pr-8 w-full md:p-4">
            <img class="svgOcchio" (click)="this.fieldTextType = !this.fieldTextType"
              src="../../../../assets/svg/occhio.svg" alt="">

          </div>
          <div *ngIf="form.get('password').invalid && form.get('password').touched" i18n class="text-red-500">Il campo
            Password è
            obbligatorio
          </div>


          <div class="relative">
            <input [type]="fieldTextType ? 'text' : 'password'" placeholder="Conferma Password" i18n-placeholder
              formControlName="confermaPassword" class="mt-4 bg-gray-100 p-2 outline-0 rounded pr-8 w-full md:p-4">
            <img class="svgOcchio" (click)="this.fieldTextType = !this.fieldTextType"
              src="../../../../assets/svg/occhio.svg" alt="">

          </div>
          <div *ngIf="form.get('confermaPassword').invalid && form.get('confermaPassword').touched" class="text-red-500"
            i18n>Il campo Conferma Password è obbligatorio
          </div>

          <div class="flex items-center mt-4">
            <input type="checkbox" formControlName="acceptPolicy" id="acceptPolicy" class="mr-2 text-start">
            <label for="acceptPolicy" class="text-[6px] md:text-sm" id="fontGrande" i18n>Procedendo acconsento al
              trattamento dei dati
              personali e
              <a href="condizioni-generali-di-vendita">accetto l'informativa sulla privacy.</a></label>
          </div>
          <div *ngIf="form.get('acceptPolicy').invalid && form.get('acceptPolicy').touched" class="text-red-500" i18n>È
            necessario accettare la policy</div>


          <div class="mb-3">
            <button type="submit" [disabled]="!form.valid" (click)="handleAuth()" i18n
              class="mt-4 w-full cursor-pointer aviano-regular text-xl text-white bg-[#CC0000] disabled:bg-neutral-600 py-3">
              Registrati
            </button>
          </div>

          <h2 (click)="typeForm = 'login'" class="text-xl mb-6 cursor-pointer rajdhani-medium text-center py-2" i18n>
            Hai già un account?
            <span class="rajdhani-semibold text-[#CC0000]">Accedi</span>
          </h2>
        </ng-container>

        <ng-container *ngIf="typeForm == 'double-optin'">

          <div class="h-auto w-full flex flex-col justify-center">
            <h5 class="text-xl rajdhani-medium  mb-4 text-center py-2" i18n>{{nome}}, Ti sto inviando una mail
              a {{form.get('email').value}} <strong>(controlla anche in spam)</strong>, aprila e digita il codice OTP
            </h5>

            <div class="w-full py-2 px-4 mb-4 bg-green-400 flex justify-center items-center">
              <i class="pi text-white pi-whatsapp mr-2"></i>
              <h5 class="text-md text-white rajdhani-medium text-center py-2" i18n>Controlla anche WhatsApp
                al numero <strong>{{form.get('telefono').value}}</strong></h5>
            </div>
          </div>


          <div id="otp" class="flex mb-4 flex-row justify-center text-center px-2 mt-5">
            <input #firstInput class="m-2 border h-10 w-10 text-center form-control rounded" type="text" id="first"
              maxLength="1" [(ngModel)]="inputValues.first" [ngModelOptions]="{standalone: true}"
              (keyup)="handleKeyUp(firstInput, 1)" />
            <input #secondInput class="m-2 border h-10 w-10 text-center form-control rounded" type="text" id="second"
              maxLength="1" [(ngModel)]="inputValues.second" [ngModelOptions]="{standalone: true}"
              (keyup)="handleKeyUp(secondInput, 2)" />
            <input #thirdInput class="m-2 border h-10 w-10 text-center form-control rounded" type="text" id="third"
              maxLength="1" [(ngModel)]="inputValues.third" [ngModelOptions]="{standalone: true}"
              (keyup)="handleKeyUp(thirdInput, 3)" />
            <input #fourthInput class="m-2 border h-10 w-10 text-center form-control rounded" type="text" id="fourth"
              maxLength="1" [(ngModel)]="inputValues.fourth" [ngModelOptions]="{standalone: true}"
              (keyup)="handleKeyUp(fourthInput, 4)" />
            <input #fifthInput class="m-2 border h-10 w-10 text-center form-control rounded" type="text" id="fifth"
              maxLength="1" [(ngModel)]="inputValues.fifth" [ngModelOptions]="{standalone: true}"
              (keyup)="handleKeyUp(fifthInput, 5)" />
            <input #sixthInput class="m-2 border h-10 w-10 text-center form-control rounded" type="text" id="sixth"
              maxLength="1" [(ngModel)]="inputValues.sixth" [ngModelOptions]="{standalone: true}"
              (keyup)="handleKeyUp(sixthInput, 6)" />
          </div>

          <p *ngIf="errore" class="text-lg text-center font-bold text-red-500 py-2">{{ errore }}</p>

          <button type="submit" (click)="doubleOptin()" i18n
            class="mb-4 mt-4 w-full cursor-pointer aviano-regular text-xl text-white bg-[#CC0000] disabled:bg-neutral-600 py-3">
            Conferma
          </button>

        </ng-container>

        <ng-container *ngIf="typeForm == 'otp'">

          <h5 class="text-xl rajdhani-medium  mb-4 text-center py-2" i18n>Ti sto inviando una mail
            a {{form.get('email').value}} controlla anche in spam, aprila e inserisci il codice OTP</h5>

          <div id="otp" class="flex mb-4 flex-row justify-center text-center px-2 mt-5">
            <input #firstInput class="m-2 border h-10 w-10 text-center form-control rounded" type="text" id="first"
              maxLength="1" [(ngModel)]="inputValues.first" [ngModelOptions]="{standalone: true}"
              (keyup)="handleKeyUp(firstInput, 1)" />
            <input #secondInput class="m-2 border h-10 w-10 text-center form-control rounded" type="text" id="second"
              maxLength="1" [(ngModel)]="inputValues.second" [ngModelOptions]="{standalone: true}"
              (keyup)="handleKeyUp(secondInput, 2)" />
            <input #thirdInput class="m-2 border h-10 w-10 text-center form-control rounded" type="text" id="third"
              maxLength="1" [(ngModel)]="inputValues.third" [ngModelOptions]="{standalone: true}"
              (keyup)="handleKeyUp(thirdInput, 3)" />
            <input #fourthInput class="m-2 border h-10 w-10 text-center form-control rounded" type="text" id="fourth"
              maxLength="1" [(ngModel)]="inputValues.fourth" [ngModelOptions]="{standalone: true}"
              (keyup)="handleKeyUp(fourthInput, 4)" />
            <input #fifthInput class="m-2 border h-10 w-10 text-center form-control rounded" type="text" id="fifth"
              maxLength="1" [(ngModel)]="inputValues.fifth" [ngModelOptions]="{standalone: true}"
              (keyup)="handleKeyUp(fifthInput, 5)" />
            <input #sixthInput class="m-2 border h-10 w-10 text-center form-control rounded" type="text" id="sixth"
              maxLength="1" [(ngModel)]="inputValues.sixth" [ngModelOptions]="{standalone: true}"
              (keyup)="handleKeyUp(sixthInput, 6)" />
          </div>

          <p *ngIf="errore" class="text-lg text-center font-bold text-red-500 py-2">{{ errore }}</p>

          <button type="submit" (click)="checkVerificationCode()" i18n
            class="mb-4 mt-4 w-full cursor-pointer aviano-regular text-xl text-white bg-[#CC0000] disabled:bg-neutral-600 py-3">
            Avanti
          </button>

        </ng-container>

        <ng-container [formGroup]="changePassword" *ngIf="typeForm == 'changePassword'">

          <h2 class="text-3xl rajdhani-medium text-center py-2" i18n>Cambia password</h2>

          <p *ngIf="errore" class="text-lg text-center font-bold text-red-500 py-2">{{ errore }}</p>

          <div class="relative">
            <input [type]="fieldTextType ? 'text' : 'password'" placeholder="Password" formControlName="password"
              i18n-placeholder class="mt-4 bg-gray-100 p-4 outline-0 rounded pr-8 w-full">
            <svg class="absolute right-2 top-5 w-5 h-5" width="20" height="20" viewBox="0 0 20 20" fill="none"
              (click)="this.fieldTextType = !this.fieldTextType" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13.137 2.58691C14.5694 4.02085 15.9851 5.43715 17.4383 6.89131C17.39 6.925 17.3154 6.96285 17.2594 7.01884C14.7617 9.51396 12.2634 12.0091 9.77143 14.5099C9.41736 14.865 9.01766 15.0791 8.51117 15.1185C7.6646 15.1849 6.8201 15.2803 5.97353 15.3529C5.1788 15.4213 4.59818 14.8344 4.66661 14.0402C4.7423 13.1646 4.82213 12.2901 4.90249 11.415C4.94033 11.0034 5.09482 10.6441 5.3898 10.3481C7.94454 7.78661 10.4988 5.2246 13.0535 2.66312C13.0841 2.63253 13.1173 2.60506 13.137 2.58743V2.58691Z"
                fill="black" />
              <path
                d="M0.00246394 10.9128C0.00246394 9.05947 -0.00427544 7.20614 0.00453759 5.35281C0.0123138 3.70477 1.08543 2.34186 2.67022 1.95875C2.93617 1.89447 3.21559 1.85922 3.48932 1.85766C5.26229 1.84885 7.03579 1.85144 8.80876 1.85403C9.32095 1.85455 9.66155 2.23196 9.57135 2.68609C9.50914 2.99921 9.25408 3.22368 8.9337 3.24442C8.85386 3.2496 8.77403 3.24805 8.69419 3.24805C6.98654 3.24805 5.27888 3.24494 3.57123 3.2496C2.47737 3.25271 1.63495 3.93028 1.43432 4.96348C1.4027 5.12574 1.39959 5.29578 1.39959 5.4622C1.39752 9.11079 1.397 12.7594 1.39907 16.4075C1.39959 17.7248 2.28193 18.6107 3.59974 18.6123C7.24782 18.6154 10.8959 18.6154 14.544 18.6123C15.8545 18.6112 16.7446 17.7154 16.7472 16.4038C16.7503 14.6666 16.7503 12.9299 16.7462 11.1927C16.7457 10.8988 16.8416 10.6671 17.0961 10.5136C17.4777 10.284 17.9914 10.4929 18.1086 10.9268C18.133 11.0165 18.1413 11.1134 18.1413 11.2067C18.1433 12.9657 18.1501 14.7247 18.1402 16.4832C18.1304 18.1675 17.0277 19.5439 15.4009 19.9109C15.1412 19.9695 14.8685 19.9913 14.602 19.9918C10.9177 19.997 7.2333 19.9985 3.54893 19.9939C1.73189 19.9918 0.324917 18.7865 0.0366793 16.9943C0.00453759 16.7947 0.00557442 16.5889 0.00505601 16.3857C0.00298235 14.5614 0.00350077 12.7371 0.00401918 10.9128H0.00246394Z"
                fill="black" />
              <path
                d="M18.421 5.79797C17.0171 4.39358 15.6008 2.97676 14.1689 1.54386C14.7081 1.0467 15.1493 0.405944 15.9051 0.158142C16.8849 -0.163275 17.7698 0.0119494 18.5355 0.707661C18.8145 0.961165 19.0752 1.23644 19.3303 1.51535C20.2137 2.48116 20.2261 3.93842 19.3484 4.90785C19.0617 5.2246 18.732 5.50247 18.4215 5.79797H18.421Z"
                fill="black" />
            </svg>
          </div>
          <div
            *ngIf="changePassword.get('password').invalid && (changePassword.get('password').dirty || changePassword.get('password').touched)">
            <div *ngIf="changePassword.get('password').errors.required" class="text-red-500" i18n>
              La password è richiesta.
            </div>
          </div>


          <div class="relative">
            <input [type]="fieldTextType ? 'text' : 'password'" placeholder="Conferma Password" i18n-placeholder
              formControlName="confermaPassword" class="mt-4 bg-gray-100 p-4 outline-0 rounded pr-8 w-full">
            <svg class="absolute right-2 top-5 w-5 h-5" width="20" height="20" viewBox="0 0 20 20" fill="none"
              (click)="this.fieldTextType = !this.fieldTextType" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13.137 2.58691C14.5694 4.02085 15.9851 5.43715 17.4383 6.89131C17.39 6.925 17.3154 6.96285 17.2594 7.01884C14.7617 9.51396 12.2634 12.0091 9.77143 14.5099C9.41736 14.865 9.01766 15.0791 8.51117 15.1185C7.6646 15.1849 6.8201 15.2803 5.97353 15.3529C5.1788 15.4213 4.59818 14.8344 4.66661 14.0402C4.7423 13.1646 4.82213 12.2901 4.90249 11.415C4.94033 11.0034 5.09482 10.6441 5.3898 10.3481C7.94454 7.78661 10.4988 5.2246 13.0535 2.66312C13.0841 2.63253 13.1173 2.60506 13.137 2.58743V2.58691Z"
                fill="black" />
              <path
                d="M0.00246394 10.9128C0.00246394 9.05947 -0.00427544 7.20614 0.00453759 5.35281C0.0123138 3.70477 1.08543 2.34186 2.67022 1.95875C2.93617 1.89447 3.21559 1.85922 3.48932 1.85766C5.26229 1.84885 7.03579 1.85144 8.80876 1.85403C9.32095 1.85455 9.66155 2.23196 9.57135 2.68609C9.50914 2.99921 9.25408 3.22368 8.9337 3.24442C8.85386 3.2496 8.77403 3.24805 8.69419 3.24805C6.98654 3.24805 5.27888 3.24494 3.57123 3.2496C2.47737 3.25271 1.63495 3.93028 1.43432 4.96348C1.4027 5.12574 1.39959 5.29578 1.39959 5.4622C1.39752 9.11079 1.397 12.7594 1.39907 16.4075C1.39959 17.7248 2.28193 18.6107 3.59974 18.6123C7.24782 18.6154 10.8959 18.6154 14.544 18.6123C15.8545 18.6112 16.7446 17.7154 16.7472 16.4038C16.7503 14.6666 16.7503 12.9299 16.7462 11.1927C16.7457 10.8988 16.8416 10.6671 17.0961 10.5136C17.4777 10.284 17.9914 10.4929 18.1086 10.9268C18.133 11.0165 18.1413 11.1134 18.1413 11.2067C18.1433 12.9657 18.1501 14.7247 18.1402 16.4832C18.1304 18.1675 17.0277 19.5439 15.4009 19.9109C15.1412 19.9695 14.8685 19.9913 14.602 19.9918C10.9177 19.997 7.2333 19.9985 3.54893 19.9939C1.73189 19.9918 0.324917 18.7865 0.0366793 16.9943C0.00453759 16.7947 0.00557442 16.5889 0.00505601 16.3857C0.00298235 14.5614 0.00350077 12.7371 0.00401918 10.9128H0.00246394Z"
                fill="black" />
              <path
                d="M18.421 5.79797C17.0171 4.39358 15.6008 2.97676 14.1689 1.54386C14.7081 1.0467 15.1493 0.405944 15.9051 0.158142C16.8849 -0.163275 17.7698 0.0119494 18.5355 0.707661C18.8145 0.961165 19.0752 1.23644 19.3303 1.51535C20.2137 2.48116 20.2261 3.93842 19.3484 4.90785C19.0617 5.2246 18.732 5.50247 18.4215 5.79797H18.421Z"
                fill="black" />
            </svg>
          </div>
          <div
            *ngIf="changePassword.get('confermaPassword').invalid && (changePassword.get('confermaPassword').dirty || changePassword.get('confermaPassword').touched)">
            <div *ngIf="changePassword.hasError('passwordsMismatch')" class="text-red-500" i18n>
              Le password non corrispondono.
            </div>
            <div *ngIf="changePassword.get('confermaPassword').errors.required" class="text-red-500" i18n>
              La conferma della password è richiesta.
            </div>
          </div>

          <div class="mb-3">
            <button type="submit" [disabled]="form.get('confermaPassword').invalid && form.get('password').invalid"
              (click)="changePasswordSubmit()" i18n
              class="mt-4 w-full cursor-pointer aviano-regular text-xl text-white bg-[#CC0000] disabled:bg-neutral-600 py-3">
              Cambia Password
            </button>
          </div>

        </ng-container>


      </div>
    </div>
  </div>
</div>